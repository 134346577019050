import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton, Link, Menu, MenuItem, TextField, Theme, Typography, Autocomplete, Tabs, Tab } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useRef } from 'react';
import OZCheckbox from '../OZCheckbox/OZCheckbox';

import '../FilterDrawer/FilterDrawer.css';
import { getFilters } from '../../services/api-service';
import { DynamicfilterTypes, LocationfilterTypes, filterTypes } from '../../utils/constants';
import { ExpandLess, ExpandMore, Add } from '@mui/icons-material';
import { DropDownIcon } from '../../pages/admin/BulkUpdateSettings/AgentSelector';
import { ColumnDisplayNameMapForFilter } from '../../hooks/useColumnFilter';
import { showErrorNotification } from '../admin/common/NotiService';

const commonAutocompleteProps = {
    popupIcon: <DropDownIcon />,
    placeholder: "Select Setting",
    size: 'small' as 'small',
    limitTags: 2,
    isOptionEqualToValue: (option: any, value: any) => {
        if (typeof option === 'string' && typeof value === 'string') {
            return option === value;
        }
        return option.label === value.label;
    },
    clearIcon: null,
    disableCloseOnSelect: true,
    componentsProps: { paper: { sx: { border: "1px solid #D6D6D7", marginTop: "6px", borderRadius: "8px" } } },
    renderOption: (props: any, option: any, { selected, inputValue }: any) => <Box {...props} sx={{
        paddingLeft: "10px !important",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: '24px !important',
        overflow: 'hidden',
    }}>
        <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"} width={"100%"} fontSize={"12px"} fontWeight={400} color={"#3D4C5E"} textTransform={"capitalize"}>
            {option}
        </Typography>
    </Box>,
    renderInput: (params: any) => <TextField placeholder='Select' size='small'
        sx={{
            '& .MuiOutlinedInput-root': {
                fontSize: "14px",
                color: "#3D4C5E",
                borderRadius: '8px',
                minHeight: "30px"
            },
            minHeight: "30px"
        }}
        {...params}
        fullWidth
    />,
}

const useStyles: any = makeStyles((theme: Theme) => ({
    filterContainer: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column'
    },
    filterHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerText: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        color: '#212121'
    },
    drawerGutter: {
        paddingTop: '74px'
    }
}));

const STATE_OPTIONS = [
    'ALL',
    'AUX',
    'READY',
    'Calling',
    'BUSY',
    'PAUSED',
    'ACW',
    'EXCEPTION',
    // 'IDLE'
];

const MODE_OPTIONS = [
    'ALL',
    'INBOUND',
    'PREVIEW',
    'MANUAL',
    'PROGRESSIVE',
    'BLENDED',
    'DIGITAL'
];

type AdditionalFilterType = 'state' | 'mode';

type ChainedFilter = {
    filterType: string;
    filterSection: any;
    selectedFilters: any[];
};

type Props = {
    toggleDrawer: any;
    isOpen: boolean | undefined;
    allowedFilters?: string[];
    applyFilters?: any;
    existingSelectedFilters?: any[];
    existingFilterCategory?: any | undefined;
    applyFilterOnExistingData?: any;
    additionalApiParams?: any;
    filterType?: any;
    NewWidget?: any;
    DynamicWidget?: any;
    setResponseLength?: any;
    RunningStatusWidget?: any;
    showAdditionalFilters?: AdditionalFilterType[];
    initialAdditionalFilters?: { [key in AdditionalFilterType]?: string[] };
    filterByColumn?: { columns: string[], data: any[] };
    columnFilters?: { [key: string]: string[] };
    setColumnFilters?: (filters: { [key: string]: string[] }) => void;
    conditionChaining?: boolean;
    existingChainedFilters?: ChainedFilter[];
    existingCondition?: 'AND' | 'OR';
};

const FILTER_CONFIG = {
    state: {
        label: 'State',
        options: STATE_OPTIONS
    },
    mode: {
        label: 'Mode',
        options: MODE_OPTIONS
    }
} as const;

const CONDITION_OPTIONS = ['AND', 'OR'];

const FilterDrawerConditionWise = ({
    isOpen,
    toggleDrawer,
    NewWidget,
    allowedFilters,
    applyFilters = () => { },
    applyFilterOnExistingData = () => { },
    existingSelectedFilters = [],
    existingFilterCategory,
    additionalApiParams = {},
    setResponseLength = () => { },
    filterType = '',
    RunningStatusWidget,
    DynamicWidget,
    showAdditionalFilters = [],
    initialAdditionalFilters = {},
    filterByColumn,
    columnFilters = {},
    setColumnFilters = () => { },
    conditionChaining = false,
    existingChainedFilters = [],
    existingCondition = 'AND'
}: Props) => {
    console.log("here allowd ", allowedFilters, filterType);
    const classes = useStyles();
    const { useState, useEffect } = React;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedFilters, setSelectedFilters] = useState<any[]>(existingSelectedFilters || []);
    const [searchText, setSearchText] = useState<string>('');
    const searchInputRef = useRef<any>();
    // const [selectAllClicked, setSelectAllClicked] = useState(false);
    const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});
    const isAdditionalFilter = useMemo(() => (showAdditionalFilters || []).length > 0, [showAdditionalFilters]);
    const [filters, setFilters] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [additionalFilters, setAdditionalFilters] = useState(initialAdditionalFilters);
    const isColumnFilter = useMemo(() => Boolean(filterByColumn && filterByColumn?.columns && filterByColumn?.columns?.length > 0 && filterByColumn?.data), [filterByColumn]);
    const [localColumnFilters, setLocalColumnFilters] = useState<{ [key: string]: string[] }>(columnFilters || {});
    const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({});

    // First get allowed filter types and existing filtered list
    const allowedFilterCategoryList = allowedFilters?.length ? NewWidget ? LocationfilterTypes.filter((item) => allowedFilters.includes(item.value)) : DynamicWidget ? DynamicfilterTypes.filter((item) => allowedFilters.includes(item.value)) : filterTypes.filter((item) => allowedFilters.includes(item.value)) : [...filterTypes];
    const existingFilteredList = existingFilterCategory ? allowedFilterCategoryList?.filter(item => item.id === existingFilterCategory) : [];

    // Don't modify the original filterSection state - keep it separate from chaining
    const [filterSection, setFilterSection] = useState(
        existingFilteredList?.length > 0
            ? existingFilteredList[0]
            : allowedFilterCategoryList.length > 0
                ? allowedFilterCategoryList[0]
                : null
    );

    // Setup condition chaining menu and state - completely separate from existing filter functionality
    const [condition, setCondition] = useState<'AND' | 'OR'>(existingCondition);
    const [conditionMenuAnchorEl, setConditionMenuAnchorEl] = useState<null | HTMLElement>(null);
    const conditionMenuOpen = Boolean(conditionMenuAnchorEl);
    const [chainedFilters, setChainedFilters] = useState<ChainedFilter[]>(
        existingChainedFilters.length > 0
            ? existingChainedFilters
            : existingFilterCategory && conditionChaining
                ? [{ filterType: String(existingFilterCategory), filterSection: existingFilteredList[0], selectedFilters: existingSelectedFilters }]
                : []
    );
    const [activeFilterIndex, setActiveFilterIndex] = useState<number>(0);
    const [activeChainFilter, setActiveChainFilter] = useState<ChainedFilter | null>(
        chainedFilters.length > 0 ? chainedFilters[0] : null
    );

    // Get available filter types for condition chaining
    const getAvailableFilterTypes = () => {
        if (!conditionChaining) return allowedFilterCategoryList;

        return allowedFilterCategoryList.filter(item =>
            !chainedFilters.some(filter => filter.filterSection?.value === item.value)
        );
    };

    const availableFilterTypes = getAvailableFilterTypes();
    console.log("availableFilterTypes", availableFilterTypes)

    const [fullLengthForSelectedFilters, setFullLengthForSelectedFilters] = useState<any>(false);

    const filterCategoryElements = allowedFilterCategoryList.map((item: any, index: number) => (
        <MenuItem key={index} className="download-item" onClick={() => handleClose(item)}>
            {item.label}
        </MenuItem>
    ));

    useEffect(() => {
        if (isAdditionalFilter && initialAdditionalFilters) {
            setAdditionalFilters(initialAdditionalFilters);
        }
    }, [isAdditionalFilter, initialAdditionalFilters,]);

    useEffect(() => {
        if (isColumnFilter && columnFilters) {
            setLocalColumnFilters(columnFilters);
        }
    }, [isColumnFilter, columnFilters]);

    // Update active chain filter when switching
    useEffect(() => {
        if (conditionChaining && chainedFilters.length > 0 && activeFilterIndex < chainedFilters.length) {
            setActiveChainFilter(chainedFilters[activeFilterIndex]);
        }
    }, [activeFilterIndex, chainedFilters, conditionChaining]);

    const handleOnCheckboxChange = (event: any) => {
        // Only modify the main selectedFilters if not in chaining mode
        if (!conditionChaining) {
            if (event?.target?.checked && event?.target?.value) {
                const existingSelectedFilters: any = [...selectedFilters];
                existingSelectedFilters.push(event.target.value);
                setSelectedFilters(existingSelectedFilters);
            } else {
                const newSelectedFilters: any = selectedFilters.filter((item) => item.id !== event?.target?.value?.id);
                setSelectedFilters(newSelectedFilters);
            }
        } else {
            // In chaining mode, update the active filter's selections
            const updatedChainedFilters = [...chainedFilters];
            if (event?.target?.checked && event?.target?.value) {
                if (updatedChainedFilters[activeFilterIndex]) {
                    const currentSelections = updatedChainedFilters[activeFilterIndex].selectedFilters || [];
                    updatedChainedFilters[activeFilterIndex].selectedFilters = [...currentSelections, event.target.value];
                }
            } else {
                if (updatedChainedFilters[activeFilterIndex]) {
                    const currentSelections = updatedChainedFilters[activeFilterIndex].selectedFilters || [];
                    updatedChainedFilters[activeFilterIndex].selectedFilters = currentSelections.filter(
                        (item) => item.id !== event?.target?.value?.id
                    );
                }
            }
            setChainedFilters(updatedChainedFilters);
        }
    };

    const handleOnCheckboxChangeGroup = (event: any, campaignType: string = "") => {
        const isGroupSelection = Boolean(campaignType);
        let existingSelectedFilters: any = [...selectedFilters];
        if (isGroupSelection) {
            const campaigns = groupedCampaigns[campaignType];
            if (event?.target?.checked && event?.target?.value) {
                campaigns.forEach((campaign: any) => {
                    if (!existingSelectedFilters.some((filter: any) => filter.id === campaign.Id)) {
                        existingSelectedFilters.push({ id: campaign.Id, label: campaign.Name });
                    }
                });
            } else {
                existingSelectedFilters = existingSelectedFilters.filter(
                    (filter: any) => !campaigns.some((campaign: any) => campaign.Id === filter.id)
                );
            }
        } else {
            if (event?.target?.checked && event?.target?.value) {
                existingSelectedFilters.push({ id: event.target.value });
            } else {
                existingSelectedFilters = existingSelectedFilters.filter(
                    (filter: any) => filter.id !== event?.target?.value
                );
            }
        }
        setSelectedFilters(existingSelectedFilters);
    };

    const isChecked = (item: any) => {
        if (!conditionChaining) {
            return selectedFilters.some((filterItem) => item.Id === filterItem.id);
        } else {
            if (!chainedFilters[activeFilterIndex]) return false;
            return chainedFilters[activeFilterIndex].selectedFilters?.some((filterItem) => item.Id === filterItem.id) || false;
        }
    };

    const filterItems = searchText.length ? filters.filter((item: any) => item.Name && item.Name.toLowerCase().includes(searchText.toLowerCase())) : filters;

    const filterItemListElements = filterItems?.map((item: any, index: number) => (
        <Grid key={index} item xs={6} className="filter-checkbox-col">
            <OZCheckbox checked={isChecked(item)} label={item.Name} id={item.Id} onChange={handleOnCheckboxChange} />
        </Grid>
    ));
    const handleClose = (type: any = null) => {
        setLoading(true);
        if (type) {
            if (!conditionChaining) {
                // Original behavior
                setFilterSection(type);
                clearAll();
            } else {
                // For condition chaining
                const updatedChainedFilters = [...chainedFilters];
                if (updatedChainedFilters[activeFilterIndex]) {
                    updatedChainedFilters[activeFilterIndex].filterType = String(type.id);
                    updatedChainedFilters[activeFilterIndex].filterSection = type;
                    updatedChainedFilters[activeFilterIndex].selectedFilters = [];
                    setChainedFilters(updatedChainedFilters);
                } else {
                    updatedChainedFilters.push({
                        filterType: String(type.id),
                        filterSection: type,
                        selectedFilters: []
                    });
                    setChainedFilters(updatedChainedFilters);
                    setActiveFilterIndex(updatedChainedFilters.length - 1);
                }
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000);
        setAnchorEl(null);
    };
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSearchTextChange = (event: any) => {
        setSearchText(event?.target?.value);
    };

    const handleDoubleClick = () => {
        if (searchInputRef.current) {
            searchInputRef.current.select();
        }
    };

    const isGroupChecked = (campaignType: string) => {
        const campaigns = groupedCampaigns[campaignType];
        return campaigns.every((item: any) => selectedFilters.some((filterItem) => item.Id === filterItem.id));
    };

    const getFilterData = async () => {
        if (conditionChaining) {
            if (chainedFilters.length === 0 || !chainedFilters[activeFilterIndex]?.filterSection?.value) return;

            let section = chainedFilters[activeFilterIndex].filterSection;
            const cacheKey = section.value;

            // Check if we already have data for this filter type in cache
            if (filterCache[cacheKey]) {
                setFilters(filterCache[cacheKey]);
                setResponseLength(filterCache[cacheKey].length);
                return;
            }

            setLoading(true);
            let response: any = [];

            try {
                if (RunningStatusWidget) {
                    response = await getFilters(filterType ? filterType : "Campaign", additionalApiParams);
                } else {
                    response = await getFilters(filterType ? filterType : section.value, additionalApiParams);
                }

                // Store the data in cache
                setFilterCache(prev => ({
                    ...prev,
                    [cacheKey]: response
                }));

                setFilters(response);
                setResponseLength(response.length);
            } catch (error) {
                console.error("Error fetching filters:", error);
            } finally {
                setLoading(false);
            }
        } else {
            // Original implementation
            if (!filterSection?.value) return;
            let runningStatusFilterSection = {
                "id": 1,
                "label": "Campaign",
                "value": "Campaign"
            };

            setLoading(true);
            let response: any = [];

            try {
                if (RunningStatusWidget) {
                    response = await getFilters(filterType ? filterType : runningStatusFilterSection.value, additionalApiParams);
                } else {
                    response = await getFilters(filterType ? filterType : filterSection.value, additionalApiParams);
                }
                setFilters(response);
                setResponseLength(response.length);
            } catch (error) {
                console.error("Error fetching filters:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const groupByCampaignType = (items: any) => {
        return items.reduce((acc: any, item: any) => {
            if (!acc[item?.CampaignType]) {
                acc[item?.CampaignType] = [];
            }
            acc[item?.CampaignType].push(item);
            return acc;
        }, {});
    };

    const groupedCampaigns = groupByCampaignType(filterItems);

    //:::::::::this is for test ::::::::::::::::::://
    // // JSX structure for displaying grouped campaigns
    // const renderGroupedCampaigns = (groupedCampaigns: any) => {
    // 	return Object.keys(groupedCampaigns).map((campaignType, index) => (
    // 		<div key={index} className="campaign-group">
    // 			{/* <Typography variant="h6" style={{ fontWeight: "bold" }}>
    // 				{campaignType}
    // 			</Typography> */}
    // 			<div className="campaign-type-header">
    // 				<OZCheckbox
    // 					id={`group_${campaignType}`}
    // 					checked={isGroupChecked(campaignType)}
    // 					label={<Typography sx={{fontSize: '14px',fontWeight: 600, color: '#212121'}}>{campaignType}</Typography>}
    // 					// label={`Group_${campaignType}`}
    // 					onChange={(event: any) => handleOnCheckboxChangeGroup(event, campaignType)}
    // 				/>
    // 			</div>
    // 			<Grid container spacing={2} sx={{paddingLeft: 4, paddingTop: 1}}>
    // 				{groupedCampaigns[campaignType].map((item: any) => (
    // 					<Grid key={item.Id} item xs={6} className="filter-checkbox-col">
    // 						<OZCheckbox 
    // 							checked={isChecked(item)} 
    // 							label={item.Name} 
    // 							id={item.Id} 
    // 							onChange={handleOnCheckboxChange} 
    // 						/>
    // 					</Grid>
    // 				))}
    // 			</Grid>
    // 		</div>
    // 	));
    // };
    //:::::::::this is for test ::::::::::::::::::://


    //:::::::::this is new custom Making ::::::::::::::::::://
    const renderGroupedCampaigns = (groupedCampaigns: any) => {

        const toggleGroupVisibility = (campaignType: string) => {
            setExpandedGroups((prev) => ({
                ...prev,
                [campaignType]: !prev[campaignType],
            }));
        };

        return Object.keys(groupedCampaigns).map((campaignType, index) => (
            <div key={index} className="campaign-group">
                <div className="campaign-type-header" style={{ display: 'flex', alignItems: 'center' }}>
                    <OZCheckbox
                        id={`group_${campaignType}`}
                        checked={isGroupChecked(campaignType)}
                        label={
                            <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#212121' }}>
                                {campaignType}
                            </Typography>
                        }
                        onChange={(event: any) => handleOnCheckboxChangeGroup(event, campaignType)}
                    />
                    <IconButton onClick={() => toggleGroupVisibility(campaignType)} size="small">
                        {expandedGroups[campaignType] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </div>

                {expandedGroups[campaignType] && (
                    <Grid container spacing={2} sx={{ paddingLeft: 4, paddingTop: 1 }}>
                        {groupedCampaigns[campaignType].map((item: any) => (
                            <Grid key={item.Id} item xs={6} className="filter-checkbox-col">
                                <OZCheckbox
                                    checked={isChecked(item)}
                                    label={item.Name}
                                    id={item.Id}
                                    onChange={handleOnCheckboxChange}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </div>
        ));
    };

    //:::::::::this is new custom Making ::::::::::::::::::://

    const selectAll = () => {
        if (!conditionChaining) {
            // Original implementation
            setSelectedFilters(filterItems.map((item: any) => ({ id: item.Id, label: item.Name })));
        } else {
            // For chaining
            const updatedChainedFilters = [...chainedFilters];
            if (updatedChainedFilters[activeFilterIndex]) {
                updatedChainedFilters[activeFilterIndex].selectedFilters =
                    filterItems.map((item: any) => ({ id: item.Id, label: item.Name }));
                setChainedFilters(updatedChainedFilters);
            }
        }
    };

    const clearAll = () => {
        if (!conditionChaining) {
            // Original implementation
            setSelectedFilters([]);
            setFullLengthForSelectedFilters(false);
        } else {
            // For chaining
            const updatedChainedFilters = [...chainedFilters];
            if (updatedChainedFilters[activeFilterIndex]) {
                updatedChainedFilters[activeFilterIndex].selectedFilters = [];
                setChainedFilters(updatedChainedFilters);
            }
            setFullLengthForSelectedFilters(false);
        }
    };

    function checkValidForLength(filtersSelected: any, filters: any) {
        const selectedLength = filtersSelected.length;
        const filtersLength = filters.length;
        if (selectedLength === filtersLength) {
            setFullLengthForSelectedFilters(false);
            return true;
        }
        if (selectedLength > 50) {
            setFullLengthForSelectedFilters(true);
            return false;
        }
        if (selectedLength > 0 && selectedLength <= 50) {
            setFullLengthForSelectedFilters(false);
            return true;
        }
        return false;
    }

    // Apply filters with separate logic for chaining
    const handleApplyFilters = () => {
        if (conditionChaining) {
            const campaignLimit = 50;
            const campaignLimitCheck = chainedFilters.find((item: any) => item.filterSection?.value === "Campaign");
            if (campaignLimitCheck) {
                if (campaignLimitCheck.selectedFilters.length > campaignLimit) {
                    showErrorNotification("Campaign limit reached, maximum 50 campaigns can be selected");
                    return;
                }
            }
            // Convert chained filters to the expected format
            // const formattedFilters = chainedFilters.map(filter => ({
            //     filterType: filter.filterSection?.value || String(filter.filterType),
            //     filterIds: filter.selectedFilters,
            //     // Include any other properties you need
            // }));

            // Apply the chained filters
            applyFilters({
                condition,
                chainedFilters: chainedFilters,
                additionalFilters,
                columnFilters: localColumnFilters
            });

            if (setColumnFilters) {
                setColumnFilters(localColumnFilters);
            }
        } else {
            // Original non-chained filter logic
            if (selectedFilters.length <= 0) {
                applyFilters({
                    selectedFilters,
                    filterType: filterSection?.id,
                    filterSection: filterSection,
                    additionalFilters,
                    columnFilters: localColumnFilters
                });
                if (setColumnFilters) {
                    setColumnFilters(localColumnFilters);
                }
            } else if (selectedFilters.length > 0) {
                let lengthCheck = checkValidForLength(selectedFilters, filters);
                if (lengthCheck) {
                    applyFilters({
                        selectedFilters,
                        filterType: filterSection?.id,
                        filterSection: filterSection,
                        additionalFilters,
                        columnFilters: localColumnFilters
                    });
                    if (setColumnFilters) {
                        setColumnFilters(localColumnFilters);
                    }
                }
            }
        }
    };

    // New methods for condition chaining
    const handleConditionMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setConditionMenuAnchorEl(event.currentTarget);
    };

    const handleConditionMenuClose = (selectedCondition?: 'AND' | 'OR') => {
        if (selectedCondition) {
            setCondition(selectedCondition);
        }
        setConditionMenuAnchorEl(null);
    };

    const switchToFilterGroup = (index: number) => {
        if (chainedFilters[index]) {
            setActiveFilterIndex(index);
            // Set the filters from cache when switching tabs
            const section = chainedFilters[index].filterSection;
            if (section && section.value && filterCache[section.value]) {
                setFilters(filterCache[section.value]);
                setResponseLength(filterCache[section.value].length);
            } else {
                // If not in cache, we'll need to fetch
                setFilters([]);
            }
        }
    };

    // Determine which filter section to display in the UI
    const displayFilterSection = (conditionChaining && chainedFilters && chainedFilters[activeFilterIndex])
        ? chainedFilters[activeFilterIndex]?.filterSection
        : filterSection;

    useEffect(() => {
        if (isOpen) {
            getFilterData();
        }
        return () => { };
    }, [isOpen, filterSection, conditionChaining]);

    // Add a separate effect for when filter data actually needs to be loaded in chaining mode
    useEffect(() => {
        // Only call getFilterData when a new filter type is added or when active tab changes
        // and the filter data for that tab hasn't been loaded yet
        if (isOpen && conditionChaining && chainedFilters?.length > 0 && activeFilterIndex < chainedFilters?.length) {
            const currentFilter = chainedFilters[activeFilterIndex];
            const cacheKey = currentFilter?.filterSection?.value;

            // Only fetch if we don't have this filter type in cache yet
            if (cacheKey && !filterCache[cacheKey]) {
                getFilterData();
            }
        }
    }, [isOpen, conditionChaining, chainedFilters, activeFilterIndex, filterCache]);

    useEffect(() => {
        checkValidForLength(selectedFilters, filters)
    }, [isOpen]);

    useEffect(() => {
        setSelectedFilters(existingSelectedFilters);
    }, [existingSelectedFilters]);

    React.useEffect(() => {
        if (existingSelectedFilters?.length > 0) {
            getFilteredSelectedData();
        }
    }, []);

    const getFilteredSelectedData = async () => {
        if (!filterSection?.value) return;
        const responseData: any = await getFilters(filterType ? filterType : filterSection.value, additionalApiParams);
        const filteredSelectedFilters = selectedFilters.filter((filter: any) =>
            responseData.some((responseFilter: any) => responseFilter.Id === filter.id)
        );
        applyFilterOnExistingData(filteredSelectedFilters);
        setSelectedFilters(filteredSelectedFilters)
    };

    const handleAdditionalFilterChange = (type: AdditionalFilterType, value: string | any[]) => {
        setAdditionalFilters((prevFilters) => ({
            ...prevFilters,
            [type]: value
        }));
    };

    const getUniqueValuesForColumn = (column: string) => {
        if (!filterByColumn || !filterByColumn.data) return [];
        const uniqueValues = new Set<string>();
        filterByColumn.data.forEach(item => {
            if (item && item[column] !== undefined && item[column] !== null) {
                uniqueValues.add(String(item[column]));
            }
        });
        return Array.from(uniqueValues).sort();
    };

    const handleColumnFilterChange = (column: string, values: string[]) => {
        setLocalColumnFilters(prev => ({
            ...prev,
            [column]: values
        }));
    };

    return (
        <Drawer open={isOpen} anchor={'right'} onClose={() => toggleDrawer(false)}>
            {conditionChaining ? (<Box sx={{ width: 455 }} className={classes.filterContainer}>
                <div className={classes.drawerGutter} style={{ marginBottom: displayFilterSection?.label === 'Campaign' ? '10px' : '0px' }}>
                    <div className={classes.filterHeader}>
                        <span className={classes.headerText}>Filter</span>
                        <IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
                            <CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
                        </IconButton>
                    </div>
                    {isColumnFilter && filterByColumn && <ColumnFiltersRenderer
                        columns={filterByColumn.columns}
                        columnFilters={localColumnFilters}
                        handleColumnFilterChange={handleColumnFilterChange}
                        getUniqueValuesForColumn={getUniqueValuesForColumn}
                    />}
                    {isAdditionalFilter && <AdditionalFiltersRenderer
                        showAdditionalFilters={showAdditionalFilters}
                        additionalFilters={additionalFilters}
                        handleAdditionalFilterChange={handleAdditionalFilterChange}
                    />}

                    {conditionChaining && (
                        <Box sx={{ mb: 2 }}>
                            <Grid container spacing={1} alignItems="center" mt={1}>
                                <Grid item xs={10}>
                                    <Typography fontSize={12} fontWeight={400} color={'#666'} mb={0.5}>
                                        Add Filter Types
                                    </Typography>
                                    <Autocomplete
                                        {...commonAutocompleteProps}
                                        multiple
                                        limitTags={3}
                                        options={availableFilterTypes.length > 0 ? availableFilterTypes.map(type => ({
                                            value: String(type.id),
                                            label: type.label,
                                            filterType: type
                                        })) : []}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"} width={"100%"} fontSize={"12px"} fontWeight={400} color={"#3D4C5E"} textTransform={"capitalize"}>
                                                    {option.label || ''}
                                                </Typography>
                                            </li>
                                        )}
                                        onChange={(_, newValue) => {
                                            console.log("on change filter autocomplete", newValue)
                                            // Map the new values to filter types

                                            // Create a new array for the updated filters
                                            const updatedChainedFilters: ChainedFilter[] = [];

                                            // First, add any existing filters that are still selected, preserving their selections
                                            newValue.forEach(item => {
                                                // Check if this filter type already exists
                                                const existingFilter = chainedFilters.find(
                                                    filter => String(filter.filterType) === item.value
                                                );

                                                if (existingFilter) {
                                                    // Keep existing filter with its selections
                                                    updatedChainedFilters.push(existingFilter);
                                                } else {
                                                    // Add new filter type with empty selections
                                                    updatedChainedFilters.push({
                                                        filterType: item.value,
                                                        filterSection: item.filterType,
                                                        selectedFilters: []
                                                    });
                                                }
                                            });

                                            // When filters are removed, ensure we don't lose filter data in cache
                                            setChainedFilters(updatedChainedFilters);

                                            // Set active index to the first filter or keep the current one if it still exists
                                            if (updatedChainedFilters.length > 0) {
                                                const currentActiveFilterType = chainedFilters[activeFilterIndex]?.filterType;
                                                const currentActiveIndex = updatedChainedFilters.findIndex(
                                                    filter => filter.filterType === currentActiveFilterType
                                                );

                                                const newActiveIndex = currentActiveIndex !== -1 ? currentActiveIndex : 0;
                                                setActiveFilterIndex(newActiveIndex);

                                                // Update filters display for the now-active filter
                                                if (updatedChainedFilters[newActiveIndex]?.filterSection?.value) {
                                                    const cacheKey = updatedChainedFilters[newActiveIndex].filterSection.value;
                                                    if (filterCache[cacheKey]) {
                                                        setFilters(filterCache[cacheKey]);
                                                    } else {
                                                        // Schedule a fetch for this filter type if not in cache
                                                        setFilters([]);
                                                    }
                                                }
                                            } else {
                                                // Clear filters when no filter types are selected
                                                setFilters([]);
                                            }
                                        }}
                                        value={chainedFilters.length > 0 ? chainedFilters.map(filter => ({
                                            value: String(filter.filterType),
                                            label: filter.filterSection?.label || String(filter.filterType),
                                            filterType: filter.filterSection
                                        })) : []}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.value === value.value
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography fontSize={12} fontWeight={400} color={'#666'} mb={0.5}>
                                        Condition
                                    </Typography>
                                    <Box
                                        sx={{
                                            border: '1px solid #D6D6D7',
                                            borderRadius: '8px',
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '36px !important',
                                        }}
                                        onClick={handleConditionMenuOpen}
                                    >
                                        <Typography fontSize={14} fontWeight={500} color={'#212121'}>
                                            {condition}
                                        </Typography>
                                        <ExpandMore sx={{ fontSize: 18, marginLeft: '2px' }} />
                                    </Box>
                                    <Menu
                                        anchorEl={conditionMenuAnchorEl}
                                        open={conditionMenuOpen}
                                        onClose={() => handleConditionMenuClose()}
                                    >
                                        {CONDITION_OPTIONS.map((option) => (
                                            <MenuItem
                                                key={option}
                                                onClick={() => handleConditionMenuClose(option as 'AND' | 'OR')}
                                                selected={condition === option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </Grid>

                            {chainedFilters.length > 0 && (
                                <Box sx={{ width: '100%', mt: 2 }}>
                                    <Tabs
                                        value={activeFilterIndex}
                                        onChange={(_, newValue) => switchToFilterGroup(newValue)}
                                        variant="fullWidth"
                                        aria-label="filter type tabs"
                                        sx={{
                                            '& .MuiTab-root': {
                                                textTransform: 'none',
                                                minHeight: 'unset',
                                                padding: '6px 12px',
                                                fontSize: '13px'
                                            },
                                            '& .Mui-selected': {
                                                fontWeight: 600,
                                            }
                                        }}
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                    >
                                        {chainedFilters.map((filter, index) => (
                                            <Tab
                                                key={index}
                                                label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                        <Typography fontSize={13}>
                                                            {filter.filterSection?.label || 'Filter ' + (index + 1)}
                                                            {filter.selectedFilters?.length > 0 && ` (${filter.selectedFilters.length})`}
                                                        </Typography>
                                                    </Box>
                                                }
                                                sx={{
                                                    border: '1px solid #E0E0E0',
                                                    borderRadius: '8px',
                                                    mx: 0.5,
                                                    marginBottom: '8px',
                                                    '&.Mui-selected': {
                                                        borderColor: (theme) => theme.palette.primary.main,
                                                        backgroundColor: (theme) => `${theme.palette.primary.main}10`,
                                                    }
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </Box>
                            )}
                        </Box>
                    )}

                    {(!conditionChaining || (conditionChaining && chainedFilters.length > 0)) && (
                        <div className="drawer-search-container">
                            <>
                                <div className="drawer-search-dd" onClick={handleMenuOpen}>
                                    {RunningStatusWidget && <span>Campaign</span>}
                                    {!RunningStatusWidget && <span>{displayFilterSection?.label || 'Select Filter'}</span>}
                                </div>
                                {!RunningStatusWidget &&
                                    <Menu
                                        id="download-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                        open={open}
                                        onClose={() => handleClose()}
                                        className="custom-search-dropdown"
                                    >
                                        {conditionChaining
                                            ? availableFilterTypes.map((item: any, index: number) => (
                                                <MenuItem key={index} className="download-item" onClick={() => handleClose(item)}>
                                                    {item.label}
                                                </MenuItem>
                                            ))
                                            : filterCategoryElements
                                        }
                                    </Menu>
                                }
                            </>
                            <div className="drawer-search-bar">
                                <input type="text" ref={searchInputRef} placeholder="Search" value={searchText} onChange={handleSearchTextChange} onDoubleClick={handleDoubleClick} />
                                <img src={`${process.env.PUBLIC_URL}/img/search-icon.svg`} width="12" alt="Filter Search"></img>
                            </div>
                        </div>
                    )}
                </div>
                {(!conditionChaining || (conditionChaining && chainedFilters.length > 0 && displayFilterSection?.label !== 'Campaign')) &&
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className="filter-select-links">
                                <Link onClick={() => selectAll()} underline="hover">
                                    Select All
                                </Link>
                                <Link onClick={() => clearAll()} underline="hover">
                                    Clear All
                                </Link>
                            </div>
                            {fullLengthForSelectedFilters && <div style={{ fontSize: '12px', fontWeight: 400, marginBottom: '10px', paddingTop: -5, color: '#FF5733' }}>Maximum 50 filters can be selected</div>}
                        </Grid>
                    </Grid>}
                <div className="oz-filter-items-container" style={{ height: (isAdditionalFilter || isColumnFilter) ? 'calc(100vh - 450px)' : 'calc(100vh - 310px)' }}>
                    {loading && (
                        <>
                            ...Loading
                        </>
                    )}
                    {(conditionChaining && chainedFilters.length === 0) ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography color="text.secondary" align="center">
                                Please select filter types to begin
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            {(displayFilterSection?.label === 'Campaign' || RunningStatusWidget) && !loading && groupedCampaigns && Object.keys(groupedCampaigns).length > 0 && !conditionChaining && (
                                <>{renderGroupedCampaigns(groupedCampaigns)}</>
                            )}
                            {((displayFilterSection?.label === 'Campaign' || RunningStatusWidget) && !loading && conditionChaining) && (
                                <Grid container spacing={2}>
                                    {filterItemListElements}
                                </Grid>
                            )}
                            {displayFilterSection?.label !== 'Campaign' && !RunningStatusWidget && !loading && (
                                <Grid container spacing={2}>
                                    {filterItemListElements}
                                </Grid>
                            )}
                        </>
                    )}
                </div>
                <div className="oz-filter-items-footer">
                    <Button variant="contained" fullWidth onClick={() => handleApplyFilters()}>
                        Apply Filter
                    </Button>
                </div>
            </Box>) : (<Box sx={{ width: 455 }} className={classes.filterContainer}>
                <div className={classes.drawerGutter}>
                    <div className={classes.filterHeader}>
                        <span className={classes.headerText}>Filter</span>
                        <IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
                            <CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
                        </IconButton>
                    </div>
                    {isColumnFilter && filterByColumn && <ColumnFiltersRenderer
                        columns={filterByColumn.columns}
                        columnFilters={localColumnFilters}
                        handleColumnFilterChange={handleColumnFilterChange}
                        getUniqueValuesForColumn={getUniqueValuesForColumn}
                    />}
                    {isAdditionalFilter && <AdditionalFiltersRenderer
                        showAdditionalFilters={showAdditionalFilters}
                        additionalFilters={additionalFilters}
                        handleAdditionalFilterChange={handleAdditionalFilterChange}
                    />}
                    <div className="drawer-search-container">
                        <>
                            <div className="drawer-search-dd" onClick={handleMenuOpen}>
                                {RunningStatusWidget && <span>Campaign</span>}
                                {!RunningStatusWidget && <span>{filterSection?.label}</span>}
                                {/* <span>{filterSection.label}</span> */}
                            </div>
                            {!RunningStatusWidget &&
                                <Menu
                                    id="download-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                    open={open}
                                    onClose={() => handleClose()}
                                    className="custom-search-dropdown"
                                >
                                    {filterCategoryElements}
                                </Menu>
                            }
                        </>
                        <div className="drawer-search-bar">
                            <input type="text" ref={searchInputRef} placeholder="Search" value={searchText} onChange={handleSearchTextChange} onDoubleClick={handleDoubleClick} />
                            <img src={`${process.env.PUBLIC_URL}/img/search-icon.svg`} width="12" alt="Filter Search"></img>
                        </div>
                    </div>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div className="filter-select-links">
                            <Link onClick={() => selectAll()} underline="hover">
                                Select All
                            </Link>
                            <Link onClick={() => clearAll()} underline="hover">
                                Clear All
                            </Link>
                        </div>
                        {fullLengthForSelectedFilters && <div style={{ fontSize: '12px', fontWeight: 400, marginBottom: '10px', paddingTop: -5, color: '#FF5733' }}>Maximum 50 filters can be selected</div>}
                    </Grid>
                </Grid>
                <div className="oz-filter-items-container" style={{ height: (isAdditionalFilter || isColumnFilter) ? 'calc(100vh - 450px)' : 'calc(100vh - 310px)' }}>
                    {loading &&
                        <>
                            ...Loading
                        </>
                    }
                    {(filterSection?.label === 'Campaign' || RunningStatusWidget) && !loading && groupedCampaigns && Object.keys(groupedCampaigns).length > 0 &&
                        <>{renderGroupedCampaigns(groupedCampaigns)}</>
                    }
                    {filterSection?.label !== 'Campaign' && !RunningStatusWidget && !loading &&
                        <Grid container spacing={2}>
                            <>{filterItemListElements}</>
                        </Grid>
                    }
                </div>
                <div className="oz-filter-items-footer">
                    <Button variant="contained" fullWidth onClick={() => handleApplyFilters()}>
                        Apply Filter
                    </Button>
                </div>
            </Box>)}
        </Drawer>
    );
};

const ColumnFiltersRenderer = ({
    columns,
    columnFilters,
    handleColumnFilterChange,
    getUniqueValuesForColumn
}: {
    columns: string[];
    columnFilters: { [key: string]: string[] };
    handleColumnFilterChange: (column: string, values: string[]) => void;
    getUniqueValuesForColumn: (column: string) => string[];
}) => {

    return (
        <Box sx={{ mb: 2, mt: 1 }}>
            <Grid container spacing={1}>
                {columns.map((column) => (
                    <Grid item xs={12} key={column}>
                        <Typography fontSize={12} fontWeight={400} color={'#212121'} sx={{ mb: .5, ml: .1 }}>
                            {ColumnDisplayNameMapForFilter[column] || column}
                        </Typography>
                        <Autocomplete
                            {...commonAutocompleteProps}
                            multiple={true}
                            options={['ALL', ...getUniqueValuesForColumn(column)]}
                            value={columnFilters[column] || []}
                            onChange={(_, newValue) => {
                                // Handle "ALL" selection logic
                                if (newValue.includes('ALL') && !(columnFilters[column] || []).includes('ALL')) {
                                    // If ALL is being selected, only select ALL
                                    handleColumnFilterChange(column, ['ALL']);
                                } else if (!newValue.includes('ALL') && (columnFilters[column] || []).includes('ALL')) {
                                    // If ALL is being unselected, clear the selection
                                    handleColumnFilterChange(column, []);
                                } else if (newValue.length > 0 && newValue.some(item => item !== 'ALL')) {
                                    // If other items are being selected, remove ALL
                                    handleColumnFilterChange(column, newValue.filter(item => item !== 'ALL'));
                                } else {
                                    handleColumnFilterChange(column, newValue);
                                }
                            }}
                            ChipProps={{ sx: { textTransform: 'capitalize' } }}
                            getOptionDisabled={(option) => (columnFilters[column] || []).includes('ALL') && option !== 'ALL'}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const AdditionalFiltersRenderer = ({
    showAdditionalFilters,
    additionalFilters,
    handleAdditionalFilterChange
}: {
    showAdditionalFilters: AdditionalFilterType[];
    additionalFilters: { [key in AdditionalFilterType]?: string[] };
    handleAdditionalFilterChange: (type: AdditionalFilterType, value: string | any[]) => void;
}) => {

    return (
        <Box sx={{ mb: 2, mt: 1 }}>
            <Grid container spacing={1}>
                {showAdditionalFilters.map((filterType) => (
                    <Grid item xs={12} key={filterType}>
                        <Typography fontSize={12} fontWeight={400} color={'#212121'} sx={{ mb: .5, ml: .1 }}>
                            {FILTER_CONFIG[filterType].label}
                        </Typography>
                        <Autocomplete
                            {...commonAutocompleteProps}
                            multiple={true}
                            options={FILTER_CONFIG[filterType].options}
                            value={additionalFilters[filterType]}
                            onChange={(_, newValue) => {
                                console.log("newValue", newValue);
                                // Handle "ALL" selection logic
                                if (newValue.includes('ALL') && !(additionalFilters[filterType] || []).includes('ALL')) {
                                    // If ALL is being selected, only select ALL
                                    handleAdditionalFilterChange(filterType, ['ALL']);
                                } else if (!newValue.includes('ALL') && (additionalFilters[filterType] || []).includes('ALL')) {
                                    // If ALL is being unselected, clear the selection
                                    handleAdditionalFilterChange(filterType, []);
                                } else if (newValue.length > 0 && newValue.some(item => item !== 'ALL')) {
                                    // If other items are being selected, remove ALL
                                    handleAdditionalFilterChange(filterType, newValue.filter(item => item !== 'ALL'));
                                } else {
                                    handleAdditionalFilterChange(filterType, newValue);
                                }
                            }}
                            getOptionDisabled={(option) => (additionalFilters[filterType] || []).includes('ALL') && option !== 'ALL'}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
export default FilterDrawerConditionWise;

