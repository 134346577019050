const SESSION_STORAGE = {
	USER_ID: 'userId',
	TOKEN: 'token',
	USER_NAME: 'userName',
	REPORTS_USER_ID: 'reportsUserId',
	REPORTS_TOKEN: 'reportsToken',
	USER_ROLES: 'userRoles',
	USER_LIST: 'userList',
	APIKEY: 'apiKey',
	USERTZ: 'userTZ',
	IS_SUPER_ADMIN: 'isSuperAdmin',
};
export const isLoggedIn = () => {
	return !!localStorage.getItem(SESSION_STORAGE.USER_ID);
};
export const getUserId = () => {
	return localStorage.getItem(SESSION_STORAGE.USER_ID);
};

export const isSuperAdmin = () => {
	return localStorage.getItem(SESSION_STORAGE.IS_SUPER_ADMIN);
};


export const getParentUserId = () => {
	return localStorage.getItem("parentUser");
};

export const setUserData = (userDetails: any) => {
	localStorage.setItem(SESSION_STORAGE.USER_ID, userDetails.UserId);
	localStorage.setItem(SESSION_STORAGE.TOKEN, userDetails.token);
	localStorage.setItem(SESSION_STORAGE.USER_NAME, userDetails.name);
	// localStorage.setItem(SESSION_STORAGE.USER_ROLES, userDetails.roles);
	localStorage.setItem(SESSION_STORAGE.APIKEY, userDetails.apiKey);
	localStorage.setItem(SESSION_STORAGE.USERTZ, userDetails.userTZ);
};

export const setRoleData = (userDetails: any) => {
	localStorage.setItem(SESSION_STORAGE.USER_ROLES, userDetails.roles);
};

export const setUserDataWithUserList = (userDetails: any) => {
	localStorage.setItem(SESSION_STORAGE.USER_ID, userDetails.UserId);
	localStorage.setItem(SESSION_STORAGE.TOKEN, userDetails.token);
	localStorage.setItem(SESSION_STORAGE.USER_NAME, userDetails.name);
	// localStorage.setItem(SESSION_STORAGE.USER_ROLES, userDetails.roles);
	localStorage.setItem(SESSION_STORAGE.USER_LIST, userDetails.userList);
	localStorage.setItem(SESSION_STORAGE.APIKEY, userDetails.apiKey);
	localStorage.setItem(SESSION_STORAGE.USERTZ, userDetails.userTZ);
};

export const getAuthToken = () => {
	return localStorage.getItem(SESSION_STORAGE.TOKEN);
};

export const getUserName = (): string | null => {
	return localStorage.getItem(SESSION_STORAGE.USER_NAME);
};

export const clearSession = () => {
	sessionStorage.clear();
	localStorage.clear();
};

export const getUserRoles = () => {
	let roles: any = localStorage.getItem(SESSION_STORAGE.USER_ROLES)
	return JSON.parse(roles) || null
}

export const getisSuperAdminTrue = () => {
	let token: any = localStorage.getItem("token")
	return token || null
}