import React, { useMemo, useState, useEffect } from 'react';
import { Button, Stack, Typography, Grid, Tooltip } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import SubUserProfileForm from "./SubUserProfileForm";
import { deleteProfileById, getFeatureListRoles, getProfileById, getsubUserProfileList } from "../../../services/api-service";
import './SubUserProfile.css';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { HISTORIC_REPORTS_LIST, LIVE_REPORTS_LIST } from '../../../utils/constants';

const groupedHistoricReports = HISTORIC_REPORTS_LIST.sort((a, b) => a.group.localeCompare(b.group)).map(report => ({
    ...report,
    component: ''
}));

const liveReports = LIVE_REPORTS_LIST.map(report => ({
    ...report,
    component: ''
}));

function SubUserProfile() {

    const [subUserForm, setSubUserForm] = useState({ isNew: false, show: false });
    const [subUserItem, setSubUserItem] = useState({});
    const [gridRef, setGridRef] = useState(null);
    const [subUserList, setSubUserList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userProfileMainPage, setUserProfileMainPage] = useState('Main');
    const [featureList, setFeatureList] = useState([]);

    useEffect(() => {
        getSubUsersList();
        getFullFeatureList();
        localStorage.removeItem('userProfileForm');
    }, []);

    const DateFormatter = ({ params }) => {
        if (params.props) {
            const date = new Date(params.props);
            const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return date.toLocaleString("en-GB", options);
        }
        const date = new Date(params.value);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleString("en-GB", options);
    }

    const vertIconMenuStyle = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "12px",
            },
        }),
        []
    );

    async function deleteUserProfileById(id) {
        setLoading(true);
        try {
            deleteProfileById(id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    getSubUsersList();
                    setLoading(false);
                } else {
                    showErrorNotification(resp.Message);
                    setLoading(false);
                }
            }).catch(e => {
                showErrorNotification("Error in Deleting Sub User, Please try again..");
                setLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
        }
    }

    async function getSubUsersList() {
        setLoading(true);
        try {
            getsubUserProfileList().then((resp) => {
                setSubUserList(resp.Data);
                setLoading(false);
            }).catch((e) => {
                setSubUserList([]);
                setLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in Sub Users list");
            setLoading(false);
        }
    };

    async function getFullFeatureList() {
        setLoading(true);
        try {
            getFeatureListRoles().then(resp => {
                if (resp?.Status === "Success" && resp?.Data?.features?.length > 0) {
                    setFeatureList(resp?.Data?.features);
                    setLoading(false);
                } else {
                    setLoading(false);
                    showErrorNotification("Fail to get List, please try again..");
                }

            }).catch(e => {
                setLoading(false);
                showErrorNotification(JSON.stringify(e));
            })

        } catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    const NameRanderer = (params) => {
        return (
            <Tooltip title={params?.value ? params?.value : ""}>
                <span style={{ color: '#3D8BF8', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{params.value}</span>
            </Tooltip>
        )
    }

    const DateForMatterRow = (params) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const vertOpen = Boolean(anchorEl);
        const anchorRef = React.useRef();
        const [deleteMode, setDeleteMode] = useState(false);

        const morevertclickInbound = (event) => {
            setTimeout(() => setAnchorEl(anchorRef?.current, 1));
        }

        const handleCloseVertInbound = () => {
            setAnchorEl(null);
        }

        const openMenuPopButton = (e, buttonType) => {
            setAnchorEl(null);
            if (buttonType === 'delete') {
                setDeleteMode(true);
            }
        }

        const closeMenuPopButton = (e, buttonType) => {
            if (buttonType === 'delete') {
                setDeleteMode(false);
            }
        }

        const onClickDeleteDialogDelete = (value) => {
            deleteUserProfileById(value.data.id);
            setDeleteMode(false);
        }

        return (
            <div className="oz-location-noOfSkill-container">
                <DateFormatter params={params} />
                <img className="locationsMorevertIcon"
                    onClick={morevertclickInbound}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
                >
                </img>
                <div ref={anchorRef}>
                    <Menu anchorEl={anchorEl} open={vertOpen} onClose={handleCloseVertInbound} sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#FFFFFF",
                            width: "133px",
                            height: "62px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-0.3625rem",
                            marginTop: "0rem",
                        },
                    }}>
                        <MenuItem onClick={(e) => openMenuPopButton(e, 'delete')} sx={{ ...vertIconMenuStyle, marginTop: '9px' }}>Delete User Profile</MenuItem>
                    </Menu>
                    {deleteMode &&
                        <DeleteDialog title={"Delete User Profile"} content={'This will delete this User Profile permanently. You cannot undo this action.'} onClose={(e) => closeMenuPopButton(e, 'delete')}
                            open={true}
                            deleteFunction={() => onClickDeleteDialogDelete(params)}
                        />
                    }
                </div>
            </div>
        )

    }

    const columnDefs = [
        {
            field: "name",
            headerName: "Profile Name",
            cellRenderer: NameRanderer
        },
        {
            field: 'createdDate', headerName: 'Created Date', enableRowGroup: false, cellRenderer: DateForMatterRow,
        }
    ];

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);

    const showSubAdminForm = () => {
        setSubUserForm({ isNew: true, show: true });
        setUserProfileMainPage('UserProfileAdd');
    };

    const closeSubAdminForm = () => {
        setUserProfileMainPage('Main');
        setSubUserForm({ isNew: false, show: false });
    };

    function onClickRow(rowItem) {
        setSubUserItem({});
        setLoading(true);
        try {
            getProfileById(rowItem.data.id).then((resp) => {
                if (resp?.Status === "Success" && resp?.Data) {
                    let data = structuredClone(resp?.Data);
                    if (typeof data.liveReports === 'string' || typeof data.liveReports === 'boolean') {
                        if (data.liveReports === 'true' || data.liveReports === true) {
                            data.liveReports = liveReports;
                        } else {
                            data.liveReports = []
                        }
                    }
                    if (typeof data.historicReports === 'string' || typeof data.historicReports === 'boolean') {
                        if (data.historicReports === 'true' || data.historicReports === true) {
                            data.historicReports = groupedHistoricReports;
                        } else {
                            data.historicReports = []
                        }
                    }
                    const lookup = featureList.reduce((acc, item) => {
                        acc[item.id] = item.name;
                        return acc;
                    }, {});


                    if (data.profileSetting) {
                        data.profileSetting = JSON.parse(data.profileSetting);
                    }

                    function profileSettingToArray(profileSetting) {
                        return Object.keys(profileSetting).map(key => {
                            return {
                                [key]: profileSetting[key]
                            };
                        });
                    }

                    const profileSettingArray = profileSettingToArray(data.profileSetting);
                    function convertBack(profileSettingArray) {
                        const output = {
                            profileSetting: [],
                            dashboard: false,
                            reports: false,
                            liveReports: [],
                            historicReports: []
                        };

                        profileSettingArray.forEach(item => {
                            const keys = Object.keys(item);

                            keys.forEach(key => {
                                if (key === "dashboard") {
                                    output.dashboard = item[key];
                                } else if (key === "reports") {
                                    output.reports = item[key];
                                } else if (key === "liveReports") {
                                    output.liveReports = (typeof item[key] === 'string' || typeof item[key] === 'boolean') ?
                                        (item[key] === 'true' || item[key] === true) ? liveReports : [] : item[key];
                                } else if (key === "historicReports") {
                                    output.historicReports = (typeof item[key] === 'string' || typeof item[key] === 'boolean') ?
                                        (item[key] === 'true' || item[key] === true) ? groupedHistoricReports : [] : item[key];
                                } else {
                                    const configObj = {
                                        configutaions: item[key].map(name => ({
                                            id: parseInt(Object.keys(lookup).find(id => lookup[id] === name)),
                                            name: name,
                                        })),
                                        access: key,
                                    };
                                    output.profileSetting.push(configObj);
                                }
                            });
                        });

                        return output;
                    }
                    const newData = convertBack(profileSettingArray);
                    data.profileSetting = newData?.profileSetting;
                    data.dashboard = newData?.dashboard;
                    data.reports = newData?.reports;
                    data.liveReports = newData?.liveReports;
                    data.historicReports = newData?.historicReports;
                    setSubUserItem(data);
                } else {
                    showErrorNotification("Error in getting Users Details, Please try again later");
                }
                setLoading(false);
            }).catch((e) => {
                setSubUserItem({});
                setLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in Sub Users Details ");
            setLoading(false);
        }
        setSubUserForm({ isNew: false, show: true })
        setUserProfileMainPage('true');
    };

    return (
        <>
            {userProfileMainPage === 'Main' &&
                <>
                    <div className="ozAdminUsersContainer">
                        <Grid sx={{ marginBottom: "10px" }}>
                            <Typography className='oz-page-header'>People</Typography>
                        </Grid>
                        <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
                            <Typography className='oz-page-heading'>User Profile List</Typography>
                            <Button
                                sx={buttonStyle}
                                variant="contained"
                                startIcon={<AddBoxOutlinedIcon />}
                                onClick={showSubAdminForm}
                            >
                                Add Profile
                            </Button>
                        </Stack>
                        <OZGridToolbar modifyHeaderName={['Profile Name', 'Created Date']} modifyHeaderNameLocation={['A1', 'B1']} title='Profile List' searchPlaceholder='Profile Name' rowCount={subUserList.length} dataGridRef={gridRef} fileName='SubUsersProfile' />
                        {loading ?
                            (
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                                    <LoadingScreen />
                                </div>
                            ) :
                            (
                                <OZDataGrid height="26.3rem" columns={columnDefs} data={subUserList} rowClickCallback={onClickRow} setGridRef={setGridRef} />
                            )
                        }
                    </div >
                </>
            }

            {(subUserForm.show && subUserForm.isNew && userProfileMainPage === 'UserProfileAdd') && <SubUserProfileForm
                onClose={closeSubAdminForm}
                updateUserProfile={getSubUsersList}
                liveReports={liveReports}
                historicReports={groupedHistoricReports}
            />}

            {userProfileMainPage === 'true' &&
                <>
                    {loading ?
                        (
                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                                <LoadingScreen />
                            </div>
                        ) :
                        (
                            <>
                                {(subUserForm.show && !subUserForm.isNew) && <SubUserProfileForm
                                    onClose={closeSubAdminForm}
                                    data={subUserItem}
                                    updateUserProfile={getSubUsersList}
                                    liveReports={liveReports}
                                    historicReports={groupedHistoricReports}
                                />}
                            </>
                        )
                    }
                </>
            }
        </>
    )
};

export default SubUserProfile;