import React from 'react';
import { FormControl, MenuItem, Select, FormHelperText, Box, Chip, Typography, IconButton, RadioGroup, FormControlLabel, Radio, TextField, Button, Divider, InputAdornment } from '@mui/material';
import { connect, getIn } from "formik";
import OZCheckbox from '../../OZCheckbox/OZCheckbox';

const OZSelectV2 = connect(({ name, label, options, multiSelect, placeholder, disabled, showCheckbox, showChip, optionLabel, formik, leftradius, rightradius, small, prePost, retry }) => {
    const error = getIn(formik.errors, name);

    const values = getIn(formik.values, name);
    const [selectedOption, setSelectedOption] = React.useState(values);
    const selected = (values === 0) ? values : (values || (multiSelect ? [] : ''))
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filteredOptions, setFilteredOptions] = React.useState(options);

    React.useEffect(() => {
        if (options) {
            filterOptions();
        }
    }, [searchTerm, options]);

    const filterOptions = () => {
        if (!searchTerm.trim()) {
            setFilteredOptions(options);
            return;
        }

        const filtered = options.filter(option => {
            if (typeof option === 'object' && optionLabel) {
                return option[optionLabel].toString().toLowerCase().includes(searchTerm.toLowerCase());
            } else if (typeof option === 'string') {
                return option.toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return option.toString().toLowerCase().includes(searchTerm.toLowerCase());
            }
        });

        setFilteredOptions(filtered);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOptionChange = ({ target: { value } }) => {
        setSelectedOption(options.filter((item) => item.id === value)[0]);
        setIsOpen(true);
    }

    const handleApply = () => {
        formik.setFieldValue(name, selectedOption);
        setIsOpen(false);
    }

    const handleInputChange = ({ target: { value } }) => {
        setSelectedOption((prev) => ({ ...prev, value: value }))
        setIsOpen(true);
    }

    const getTouchedStatus = () => {
        let touched = getIn(formik.touched, name);
        if (touched) {
            if (multiSelect) {
                if (touched.length === 0) {
                    return true
                } else {
                    return touched
                }
            } else {
                return touched
            }

        } else {
            return touched
        }
    }

    const touch = getTouchedStatus()
    const customSelectedValue = (seletedList) => {
        if (optionLabel) {
            let optionselected = seletedList.map((item) => (
                item[optionLabel]
            ))
            return optionselected?.join(', ');
        }
        return seletedList?.join(', ');
    }

    const handleOpen = () => {
        setIsOpen(true);
        setSearchTerm('');
        setFilteredOptions(options);
    };

    const handleClose = () => {
        setIsOpen(false);
        setSearchTerm('');
    };

    const customRenderValue = (selected) => {
        const Placeholder = <Typography style={{ color: name === "category" || name === "isZip" ? '#536580' : 'black', opacity: '.4' }} aria-disabled>{placeholder}</Typography>
        if (multiSelect) {
            return selected?.length === 0 ? Placeholder : showChip ? <Box sx={{ display: "flex", overflow: "hidden", }}>
                {<div style={{ display: "flex" }}>
                    {selected.map((value, index) => (index < 2 ?
                        optionLabel ? <Chip sx={{ marginRight: '5px' }} key={value + index} label={value} /> : <Chip sx={{ marginRight: '5px' }} key={value} label={value} />
                        : ""
                    ))}
                    {selected.length > 2 && <Typography sx={{
                        display: "flex", alignSelf: "center", fontSize: "14px",
                        fontWeight: 400,
                        color: "#99A0A8",
                        paddingRight: '27px',
                    }}>{("... +" + (selected.length - 2))}</Typography>}
                </div>
                }
            </Box> : customSelectedValue(selected);
        }
        if (prePost) {
            let operator = '';
            if (selectedOption.id === 'Equal to') {
                operator = '=';
            } else if (selectedOption.id === 'Less than or equal to') {
                operator = '<=';
            } else if (selectedOption.id === 'Greater than or equal to') {
                operator = '>=';
            } else if (selectedOption.id === 'Less than') {
                operator = '<';
            } else if (selectedOption.id === 'Greater than') {
                operator = '>';
            }
            return (selectedOption.id === "" || selectedOption.id === undefined) ? Placeholder : <Box sx={{ display: "flex", overflow: "hidden", }}>
                {<div style={{ display: "flex" }}>
                    <Chip label={`${selectedOption.id} (${operator}) ${selectedOption.value || ''}`} />
                </div>
                }
            </Box>
        }
        return (selected === "" || selected === undefined) ? Placeholder : selected
    }

    const isChecked = (option) => {
        let isSelected = false;
        let values = getIn(formik.values, name);
        if (typeof values === 'undefined' || values.length === 0) {
            return isSelected
        }
        if (typeof option === 'object') {
            values.forEach(element => {
                if (element[optionLabel] === option[optionLabel]) {
                    isSelected = true;
                }
            });
        }
        else {
            isSelected = values.indexOf(option) > -1;
        }
        return isSelected;
    }

    const DropDownIcon = () => {
        return <IconButton sx={{
            marginRight: '10px', position: 'absolute !important', right: '0', pointerEvents: 'none !important'
        }}><img
            src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
            alt="edit"
        ></img></IconButton>
    };

    const mapSelectedItemps = () => {
        try {
            let selected = (values === 0) ? values : (values || (multiSelect ? [] : ''));
            if (multiSelect) {
                if (selected.length > 0 && typeof selected[0] === 'object') {
                    let data = []
                    selected.forEach(item => {
                        data.push(item[optionLabel])
                    })
                    return data;
                } else {
                    return selected
                }
            } else {
                let value = null;
                if (typeof selected === 'object') {
                    value = selected[optionLabel]
                } else {
                    value = selected;
                }
                return value === null ? selected : value
            }
        }
        catch (e) {
            console.log('Error While mapping selected items', e);
        }
    }
    const onChangeSelection = (value) => {
        try {
            if (multiSelect) {
                if (options.length > 0 && typeof options[0] === 'object') {
                    let data = []
                    value.forEach(item => {
                        options.forEach(option => {
                            if (option[optionLabel] === item) {
                                data.push(option);
                            }
                        })
                    })
                    formik.setFieldValue(name, data);
                } else {
                    formik.setFieldValue(name, value);
                }
            } else {

                options.forEach(option => {
                    let optionType = typeof option;
                    if (optionType === 'object') {
                        if (option[optionLabel] === value) {
                            formik.setFieldValue(name, option);
                            return;
                        }
                    } else {
                        if (option === value) {
                            formik.setFieldValue(name, value);
                            return;
                        }
                    }
                });
            }
        } catch (e) {
            console.log('Error While changing selected items', e)
        }
    }

    return (
        <React.Fragment>
            <FormControl component="fieldset"
                error={touch & Boolean(error) ? true : false}
                fullWidth
            >
                {label && <Typography htmlFor={name}
                    fontSize='12px'
                    fontWeight={400}
                    lineHeight='14px'
                    marginBottom={'6px'}
                    marginLeft='1px'
                    color={disabled ? '#99A0A8' : (name === "category" || name === "isZip") ? '#47586E' : '#212121'}
                >
                    {label}
                </Typography>}
                <Select
                    MenuProps={{
                        elevation: 0,
                        autoFocus: false,
                        disableAutoFocusItem: true,
                        PaperProps: {
                            sx: {
                                marginTop: '-5px',
                                borderRadius: '12px',
                                maxHeight: '300px',
                                border: '1px solid #E2E8F0',
                                boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.05), 0 8px 10px -6px rgba(0, 0, 0, 0.02)',
                                overflow: 'hidden'
                            }
                        },
                        MenuListProps: {
                            sx: {
                                maxHeight: '300px',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                    backgroundColor: 'transparent'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#CBD5E1',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        backgroundColor: '#94A3B8'
                                    }
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent'
                                },
                                // Firefox scrollbar styling
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#CBD5E1 transparent'
                            }
                        }
                    }}
                    sx={{
                        height: '44px',
                        maxWidth: !retry ? (rightradius ? '280px !important' : leftradius ? '170px !important' : small ? '180px !important' : null) : retry ? (rightradius ? '280px !important' : leftradius ? '200px !important' : small ? '180px !important' : null) : null,
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0',
                            borderRadius: (rightradius ? "0px 12px 12px 0px !important" : leftradius ? "12px 0px 0px 12px !important" : '12px'),
                            fontSize: '14px',
                            transition: 'all 0.2s ease',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CBD5E1',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            // borderColor: '#38BDF8',
                            // borderWidth: '1px',
                            // boxShadow: '0 0 0 4px rgba(56, 189, 248, 0.1)',
                        },
                        background: (name === "isZip") && "#ffff"
                    }}
                    open={isOpen}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    name={name}
                    disabled={disabled}
                    multiple={multiSelect || false}
                    id={name}
                    value={mapSelectedItemps()}
                    onChange={(event) => {
                        onChangeSelection(event.target.value)
                    }}
                    onBlur={formik.handleBlur}
                    displayEmpty
                    renderValue={(selected) => customRenderValue(selected)}
                    IconComponent={DropDownIcon}

                >
                    {/* Search Input */}
                    <Box
                        sx={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#fff',
                            zIndex: 1,
                            padding: '16px',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.03)'
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                    >
                        <TextField
                            autoFocus
                            placeholder="Search options..."
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={(e) => {
                                // Prevent default behavior for navigation keys
                                if (['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) {
                                    e.stopPropagation();
                                }
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ color: '#94A3B8' }}
                                        >
                                            <path
                                                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </InputAdornment>
                                ),
                                endAdornment: searchTerm ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear search"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSearchTerm('');
                                            }}
                                            edge="end"
                                            size="small"
                                            sx={{
                                                color: '#94A3B8',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0,0,0,0.04)',
                                                    color: '#64748B'
                                                }
                                            }}
                                        >
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18 6L6 18M6 6L18 18"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                                sx: {
                                    borderRadius: '10px',
                                    backgroundColor: '#F1F5F9',
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: '#E2E8F0',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        transition: 'all 0.2s ease',
                                    },
                                    '&.Mui-focused': {
                                        backgroundColor: '#ffffff',
                                        // boxShadow: '0 0 0 2px rgba(56, 189, 248, 0.3)',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            // borderColor: '#38BDF8',
                                        }
                                    },
                                    '& input': {
                                        padding: '10px 14px',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        letterSpacing: '0.01em',
                                    }
                                }
                            }}
                        />
                    </Box>

                    {multiSelect && showCheckbox ? filteredOptions.map((option, index) => {
                        if (typeof option === "object") {
                            return <MenuItem value={option[optionLabel]} label={option[optionLabel]} key={index} sx={{
                                '& .custom-checkbox': { marginBottom: '0px' },
                                fontSize: '14px',
                                padding: '8px 16px',
                                transition: 'background-color 0.2s ease',
                                '&:hover': {
                                    backgroundColor: '#F1F5F9'
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#EFF6FF',
                                    '&:hover': {
                                        backgroundColor: '#DBEAFE'
                                    }
                                }
                            }}>
                                <OZCheckbox size='small' checked={isChecked(option)} />
                                {option[optionLabel]}
                            </MenuItem >
                        }
                        else if (typeof option === "string") {
                            return <MenuItem key={index} value={option} sx={{
                                '& .custom-checkbox': { marginBottom: '0px' },
                                fontSize: '14px',
                                padding: '8px 16px',
                                transition: 'background-color 0.2s ease',
                                '&:hover': {
                                    backgroundColor: '#F1F5F9'
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#EFF6FF',
                                    '&:hover': {
                                        backgroundColor: '#DBEAFE'
                                    }
                                }
                            }}>
                                <OZCheckbox size='small' checked={isChecked(option)} />
                                {option}
                            </MenuItem>
                        }
                    }) : prePost ?
                        <CustomRadioGroup handleOptionChange={handleOptionChange} optionLabel={optionLabel} options={filteredOptions} selectedOption={selectedOption} />
                        :
                        filteredOptions?.map((option, index) => {
                            if (typeof option === "object")
                                return <MenuItem value={option[optionLabel]} label={option[optionLabel]} key={index} sx={{
                                    fontSize: '14px',
                                    padding: '8px 16px',
                                    transition: 'background-color 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: '#F1F5F9'
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#EFF6FF',
                                        color: '#3B82F6',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: '#DBEAFE'
                                        }
                                    }
                                }}>
                                    {option[optionLabel]}
                                </MenuItem >
                            else if (typeof option === "string")
                                return <MenuItem value={option} label={option} key={index} sx={{
                                    fontSize: '14px',
                                    padding: '8px 16px',
                                    transition: 'background-color 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: '#F1F5F9'
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#EFF6FF',
                                        color: '#3B82F6',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: '#DBEAFE'
                                        }
                                    }
                                }}>
                                    {option}
                                </MenuItem >
                            else {
                                return <MenuItem value={option} label={option} key={index} sx={{
                                    fontSize: '14px',
                                    padding: '8px 16px',
                                    transition: 'background-color 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: '#F1F5F9'
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#EFF6FF',
                                        color: '#3B82F6',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: '#DBEAFE'
                                        }
                                    }
                                }}>
                                    {option}
                                </MenuItem >
                            }
                        })
                    }

                    {filteredOptions.length === 0 && (
                        <Box sx={{
                            py: 4,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: '#64748B'
                        }}>
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginBottom: '12px', color: '#94A3B8' }}
                            >
                                <path
                                    d="M21 21L15.5 15.5M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10 7V13"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M7 10H13"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                />
                            </svg>
                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '15px', color: '#475569' }}>
                                No matches found
                            </Typography>
                            <Typography variant="caption" sx={{ mt: 1, color: '#94A3B8', fontSize: '13px' }}>
                                Try adjusting your search term
                            </Typography>
                        </Box>
                    )}

                    {prePost &&
                        <div style={{ padding: '10px' }}>
                            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginBottom: '14px' }} />
                            </span>
                            <p style={{ marginBottom: '10px' }}>Value <span style={{ fontSize: '12px', fontWeight: '400', color: '#99A0A8' }}>(In Sec)</span></p>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        backgroundColor: '#ffff',
                                        fontSize: '14px',
                                        height: '40px',
                                        width: '120px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #D6D6D7'
                                    },
                                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #D6D6D7'
                                    }
                                }}
                                type='number'
                                value={selectedOption.value}
                                onChange={handleInputChange}
                                placeholder="In Sec"
                                variant="outlined"
                            />
                            <Button
                                sx={{ marginTop: '60px', marginLeft: '50px', borderRadius: '8px', height: '30px', borderColor: '#5BA0FF', fontWeight: '400', width: '81px' }}
                                onClick={handleApply}
                                variant="outlined" color="primary">
                                Apply
                            </Button>
                        </div>
                    }
                </Select>
                <FormHelperText>{touch & Boolean(error) ? error : null}</FormHelperText>

            </FormControl>
        </React.Fragment>
    );
})

function CustomRadioGroup({ selectedOption, options, handleOptionChange, optionLabel }) {
    return <RadioGroup
        aria-label="option"
        name="option"
        value={selectedOption[optionLabel]}
        onChange={handleOptionChange}
    >
        {options?.map((option, index) => {
            return <FormControlLabel
                sx={{ pl: '10px' }}
                value={option[optionLabel]}
                control={<Radio size='small' />}
                label={option[optionLabel]}
            />
        })}
    </RadioGroup>
}

export default OZSelectV2;