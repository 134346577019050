import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Grid, Typography, Tooltip } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import OZDrawer from '../../../components/admin/common/OZDrawer/OZDrawer';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import './Settings.css'
import OZDialog from '../../../components/admin/common/OZDialog';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { deleteSettingParamById, getRoleList, getSettingCategoryList, saveSettingParams } from '../../../services/api-service';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { ScrollToFieldError } from '../../../components/admin/common/ScrollToFieldError';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import OZSelect from '../../../components/admin/common/OZSelect';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';

const SettingParameterView = (viewProps) => {
    const DataComponent = viewProps.DataComponent;
    const TemplateFieldsView = (props) => {
        return (
            <div>
                <label className='oz-template-view-value' dangerouslySetInnerHTML={{ __html: props.data.text }} />
                <label className='oz-template-view-label'>{props.label}</label>
                {
                    (props.data.length !== 0) ?
                        <label className='oz-template-view-value'>{props.data} </label> :
                        <label className='oz-template-view-value'> - </label>
                }
            </div>
        )
    }

    let styleFieldHeader = useMemo(
        () => ({
            fontSize: "12px",
            fontWeigth: 400,
            color: "#99A0A8",
        }),
        []
    );

    let editButtonStyle = useMemo(
        () => ({
            fontWeight: 400,
            fontSize: "14px",
            color: "#3D8BF8",
            width: "32px",
            minWidth: "29px",
        }),
        []
    );

    return (
        <div className="oz-Template-view-lablel-container">
            <div style={{ display: 'flex', alignContent: 'center' }}>
                {viewProps?.data?.name &&
                    <TemplateFieldsView data={' '} label={""} />
                }
                <Typography
                    size="small"
                    variant="text"
                    sx={{ ...editButtonStyle, cursor: "pointer" }}
                    onClick={() => {
                        viewProps?.clickOnEdit();
                    }}
                    paddingtop={"5px"}
                >
                    Edit
                </Typography>

            </div>

            <DataComponent data={viewProps.data} />
            {/* {viewProps?.data?.code &&
                <TemplateFieldsView data={viewProps?.data?.code} label={"Setting Code *"} />}
            {viewProps?.data?.name &&
                <TemplateFieldsView data={viewProps?.data?.name} label={"Setting Name *"} />}
            {viewProps?.data?.['admin'] &&
                <TemplateFieldsView data={viewProps?.data?.admin} label={"Admin Setting *"} />}
            {viewProps?.data?.type &&
                <TemplateFieldsView data={viewProps?.data?.type} label={"Type *"} />} */}
        </div>
    )
}
const SettingParameterForm = (props) => {
    console.log("here data is ", props);
    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const [open, setState] = useState(true)
    const [settingParameterFormMode, setSettingParamterFormMode] = useState(initMode);
    const [settingParameterAnchorE1, setSettingParameterAnchorE1] = useState(false);
    const anchorRef = useRef();
    const opens = Boolean(settingParameterAnchorE1);
    const [openDeleteDailog, setDeleteDialog] = useState(false)
    const [categoryList, setCategoryList] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const isDisabled = useMemo(() => settingParameterFormMode === ADMIN_FORM_MODES.view, [settingParameterFormMode]);

    const validationSchema = useMemo(() => (yup.object({
        code: yup
            .string('Enter  Setting Code ')
            .min(5, "Setting Code should not be empty and it should be between 5 to 20 alphanumeric characters long and allows special characters like _, and Setting Code cannot start and end with Special characters.")
            .max(20, "Setting Code should not be empty and it should be between 5 to 20 alphanumeric characters long and allows special characters like _, and Setting Code cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9](_?[A-Za-z0-9])+)$/, "Setting Code should not be empty and it should be between 5 to 20 alphanumeric characters long and allows special characters like _, and Setting Code cannot start and end with Special characters.")
            .required('Setting Code is required'),
        name: yup
            .string('Enter Setting Name ')
            .min(5, "Setting Name should not be empty and it should be between 5 to 50 alphanumeric characters long and allows special characters like _,spaces and Setting Name cannot start and end with Special characters.")
            .max(50, "Setting Name should not be empty and it should be between 5 to 50 alphanumeric characters long and allows special characters like _,spaces and Setting Name cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_| )?[A-Za-z0-9])+)$/, "Setting Name should not be empty and it should be between 5 to 50 alphanumeric characters long and allows special characters like _,spaces and Setting Name cannot start and end with Special characters.")
            .required('Setting Name is required'),
        admin: yup.boolean()
            .required('Admin status is required'),

        parameterType: yup.object()
            .required('Category is required'),

        inputType: yup.string()
            .required('Input type is required')
            .nullable(false),

        defaultValue: yup.string()
            .required('Default value is required'),

        inputOptions: yup.string()
            .when('inputType', {
                is: 'selectbox',
                then: (schema) => schema.required('Input options are required'),
                otherwise: (schema) => schema.notRequired()
            }),

        defaultRequestType: yup.string()
            .when('inputType', {
                is: 'URL',
                then: (schema) => schema.required('Default request type is required'),
                otherwise: (schema) => schema.notRequired()
            }),

        roles: yup.array()
            .min(1, 'At least one role must be selected')
            .required('Roles are required')
    })), []);

    const closeSettingParameterForm = () => {
        props.onClose();
        setState(false);
    };

    const closeSettingParameterFormEdit = () => {
        setSettingParamterFormMode(ADMIN_FORM_MODES.view);
    };

    const EditSettingParameterForm = () => {
        setSettingParamterFormMode(ADMIN_FORM_MODES.edit);
        setState(true);
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    const morevertclick = () => {
        setTimeout(() => setSettingParameterAnchorE1(anchorRef.current), 1);
    };

    const handleMenuClose = () => {
        setSettingParameterAnchorE1(null);
    };

    function openDeleteDialog() {
        setSettingParameterAnchorE1(null);
        setDeleteDialog(true)
    };

    const deleteSettingParameter = () => {
        setSettingParameterAnchorE1(null);
        deleteSettingById(props.data.id)
        setDeleteDialog(false)
    };

    async function deleteSettingById() {
        try {
            deleteSettingParamById(props.data.id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSettingParameterForm();
                        props.emailTemplate();
                        showSuccessNotification(resp.Message)

                    } else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const onClickDiscardButton = () => {
        setSettingParamterFormMode(ADMIN_FORM_MODES.view);
    };

    async function addSettingParameter(data, isUpdate = false) {
        data.type = "email"
        try {
            saveSettingParams(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        if (isUpdate) {
                            showSuccessNotification(`Setting ${data.name} has been updated Successfully`);
                        }
                        else {
                            showSuccessNotification(`Setting ${data.name} has been added Successfully`);
                        }

                        props.emailTemplate();
                        closeSettingParameterForm();
                    }
                    else {
                        showErrorNotification(resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }

                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    function updateSettingParameter(data) {
        addSettingParameter(data, true)
    };

    const styleListItemButton = useMemo(
        () => ({
            padding: "0px",
            alignSelf: "center",
            paddingLeft: "10px",
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );


    useEffect(() => {
        getCategoryList();
        getRoleLists();
    }, [])


    async function getCategoryList() {
        try {
            getSettingCategoryList().then((resp) => {
                if (resp.Status === "Success") {
                    setCategoryList(resp.Data);
                }
            })
                .catch((e) => {
                    setCategoryList([]);
                });;
        } catch (e) {
            showErrorNotification("Error while getting categories");
        }
    };

    async function getRoleLists() {
        try {
            getRoleList()
                .then((resp) => {
                    if (resp.Status === "Success") {
                        const respData = resp?.Data?.map(item => ({
                            id: item.id,
                            name: item.name
                        }))
                        setRolesList(respData || []);
                    }
                })
                .catch((e) => {
                    setRolesList([]);
                });
        } catch (e) {
            showErrorNotification("Error while getting roles");
        }
    };
    const DataForm = (props) => {
        let initData = props.data || {
            code: '',
            name: '',
            admin: false,
            inputType: '',
            defaultValue: '',
            inputOptions: "",
            parameterType: '',
            defaultRequestType: '',
            roles: [{
                "id": -1,
                "name": "ROLE_ADMIN",
                "description": null,
                "authority": "ROLE_ADMIN"
            }],

        };
        let style1 = useMemo(() => ({
            width: '50vw',
            height: "20rem",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            maxWidth: '50vw',
            maxHeight: '70vh',
            overflowY: settingParameterFormMode === ADMIN_FORM_MODES.view ? 'auto' : 'none',
            padding: '5px'
        }), [])


        return (
            <div className='oz-email-add-form'>
                <Formik initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let data = structuredClone(values);

                        if (settingParameterFormMode === ADMIN_FORM_MODES.add) {
                            addSettingParameter(data);
                        }
                        else if (settingParameterFormMode === ADMIN_FORM_MODES.edit) {
                            updateSettingParameter(data);
                        }
                    }}>
                    {props => (
                        <form onSubmit={props.handleSubmit} id='settingForm' className='ozcw-scrollbar' style={settingParameterFormMode === ADMIN_FORM_MODES.add ? style1 :
                            style2} >
                            <Grid container spacing={1} >
                                <Grid container spacing={2} direction={'row'}>
                                    <>
                                        <Grid item xs={12} marginLeft='10px' marginTop={'35px'}>
                                            <OZInput
                                                name="code"
                                                label="Setting Code *"
                                                placeholder="Setting Code"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} marginLeft='10px'>
                                            <OZInput
                                                name="name"
                                                label="Setting Name *"
                                                placeholder="Setting Name"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} marginLeft='10px'>
                                            <OZFCheckbox
                                                name="admin"
                                                label="Admin Setting"
                                                labelPosition="end"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} marginLeft='10px'>
                                            <OZSelect
                                                name="parameterType"
                                                label="Category *"
                                                optionLabel='name'
                                                options={categoryList}
                                                placeholder="Category"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} marginLeft='10px'>
                                            <OZSelect
                                                name="inputType"
                                                label="Input Type *"
                                                options={['textfield', 'selectbox', 'checkbox', 'URL', 'JSON']}
                                                placeholder="Input Type"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        {props.values.inputType === 'URL' && <Grid item xs={12} marginLeft='10px'>
                                            <OZSelect
                                                name="defaultRequestType"
                                                label="URL Request Type *"
                                                options={['GET', 'POST']}
                                                placeholder="URL Request Type"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        }
                                        {props.values.inputType === 'selectbox' && <Grid item xs={12} marginLeft='10px'>
                                            <OZInput
                                                name="inputOptions"
                                                label="Input Options *"
                                                placeholder="Input Options"
                                                disabled={isDisabled}
                                            />
                                        </Grid>}
                                        <Grid item xs={12} marginLeft='10px'>
                                            <OZInput
                                                name="defaultValue"
                                                label="Default Value *"
                                                placeholder="Default Value"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} marginLeft='10px'>
                                            <CustomAutoComplete
                                                mode='Add'
                                                limitTags={4}
                                                name="roles"
                                                labelHeader='Role Selection *'
                                                placeholder={"Select Roles"}
                                                Datas={rolesList}
                                                optionLabelName='name'
                                                showCheckBox={true}
                                                multiple={true}
                                                popupIcon={null}
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                    </>
                                </Grid>
                            </Grid>
                            <ScrollToFieldError />
                        </form>
                    )}
                </Formik>
                {/* {settingParameterFormMode === ADMIN_FORM_MODES.edit && (
                    <div className='oz-emailtemplate-drawer-buttons' style={{ height: '50px' }}>
                        <Grid item marginRight={'10px'} >
                            <Button disabled={settingParameterFormMode === ADMIN_FORM_MODES.view} sx={{ ...buttonStyle, marginLeft: "10px" }} variant='outlined' onClick={settingParameterFormMode === ADMIN_FORM_MODES.edit ? closeSettingParameterFormEdit : closeSettingParameterForm}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item marginRight={'10px'}>
                            <Button
                                disabled={settingParameterFormMode === ADMIN_FORM_MODES.view}

                                variant="contained"
                                type="submit"
                                sx={{ ...buttonStyle, width: "130px" }}
                                form='settingForm'
                            >
                                Save Changes
                            </Button></Grid>
                    </div>)} */}
            </div >
        )
    };
    return (
        <div>
            <div>
                {settingParameterFormMode === ADMIN_FORM_MODES.add && (
                    <OZDialog
                        title="Setting Parameter"
                        formType="Setting"
                        subTitle=" Create Settings Parameter"
                        saveButtonProps={{
                            form: "settingForm",
                            type: 'submit',
                            label: 'save'
                        }}
                        open={open}
                        mode={settingParameterFormMode}
                        onClose={closeSettingParameterForm}>
                        <Divider style={{ color: "#E6E5E6" }}></Divider>
                        {settingParameterFormMode === ADMIN_FORM_MODES.add && <DataForm />}
                    </OZDialog>
                )}
            </div>
            {(settingParameterFormMode === ADMIN_FORM_MODES.view || settingParameterFormMode === ADMIN_FORM_MODES.edit) && (
                <OZDrawer
                    open={open}
                    formType='template'
                    title={
                        <div className='oz-template-drawer-textdeild-container'>
                            <div className='oz-template-drawer-title-container'>
                                <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>
                                {settingParameterFormMode !== ADMIN_FORM_MODES.edit &&
                                    <Tooltip title={props?.data?.name}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                alignSelf: 'center',
                                                marginLeft: "0px",
                                                maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                            }}
                                        >
                                            {props?.data?.name}
                                        </Typography>
                                    </Tooltip>
                                }
                                <div >
                                    <Menu
                                        anchorEl={settingParameterAnchorE1}
                                        open={opens}
                                        onClose={handleMenuClose}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                height: "37px",
                                                width: "133px",
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-8.9625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                        <MenuItem sx={{ ...styleListItemButton }} onClick={openDeleteDialog}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                            {openDeleteDailog &&
                                <DeleteDialog
                                    title={"Delete Setting Parameter"}
                                    content={`This will delete (${props.data.name}) Setting Parameter permanently. You cannot undo this action.`}
                                    onClose={() => { setDeleteDialog(false) }}
                                    open={openDeleteDailog}
                                    deleteFunction={deleteSettingParameter}
                                />
                            }
                        </div>
                    }
                    onClose={closeSettingParameterForm}
                    hideEditIcon={true}
                    // hideEditIcon={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'View')}
                    mode={settingParameterFormMode}>
                    {settingParameterFormMode === ADMIN_FORM_MODES.view && <SettingParameterView data={props.data} clickOnEdit={EditSettingParameterForm} DataComponent={DataForm} />}
                </OZDrawer>
            )}

            {settingParameterFormMode === ADMIN_FORM_MODES.edit && (
                <OZDialog
                    formType="Setting"
                    subTitle="Edit Setting Parameter"
                    // hideActions
                    open={open}
                    mode={settingParameterFormMode}
                    saveButtonProps={{
                        form: "settingForm",
                        type: 'submit',
                        label: 'Update'
                    }}
                    onClose={closeSettingParameterFormEdit}>
                    <Divider style={{ color: "#E6E5E6" }}></Divider>
                    {settingParameterFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
                </OZDialog>
            )}
        </div>
    );
};
export default SettingParameterForm;
