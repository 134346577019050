import React, { useEffect, useRef } from 'react';

// Type definitions
declare global {
  interface Window {
    initializeTemplates?: (config: AppConfig) => void;
    cleanupTemplates?: () => void;
    appConfig?: AppConfig;
  }
}

interface AppConfig {
  wabId: string;
}

interface WhatsAppTemplatesContainerProps {
  wabaId: string;
}

const WhatsAppTemplatesContainer: React.FC<WhatsAppTemplatesContainerProps> = ({ wabaId }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Create script element
    window.appConfig = { wabId: wabaId };
    const script = document.createElement('script');
    script.src = 'https://brandagentv2.ozonetel.com/WhatsApp-Templates/public/templates.js';
    script.async = true;

    // Handle script load errors
    script.onerror = () => {
      console.error('Failed to load WhatsApp templates script');
    };

    // Append script to document
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      document.body.removeChild(script);
      // Clean up any global instances or event listeners if necessary
      if (typeof window.cleanupTemplates === 'function') {
        window.cleanupTemplates();
      }
    };
  }, [wabaId]); // Re-run if wabaId changes

  return (
    <div className="w-full">
      <div
        id="ozWTContainer"
        ref={containerRef}
        className="min-h-96 border border-gray-200 rounded-lg p-4"
      >
        {/* Templates will be rendered here by the external script */}
      </div>
    </div>
  );
};

export default WhatsAppTemplatesContainer;