import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { Badge, Box, Card, Chip, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { getLiveCalls, getFilters } from '../../../services/api-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../AgentPerformance/AgentPerformance.css';
import useChartResize from '../../../hooks/useChartResize';
import useColumnFilter from '../../../hooks/useColumnFilter';

let interval: any;
function LiveCalls(props: any) {
	const { useState, useRef } = React;
	const downloadContent = useRef<null | HTMLElement>(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [liveCallsFilter, setLiveCallsFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '0');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const { chartDimensions, chartContainerRef } = useChartResize();
	const { columnFilters, setColumnFilters, filteredData, columnFilterLength, applyColumnFilters, columnFilterItems } = useColumnFilter({ rowData });
	const additionalApiParams = { campaignType: 'Predictive', CampaignPosition: 'Running' };

	const clearFilter = () => {
		setLiveCallsFilter([]);
		setColumnFilters({});
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = liveCallsFilter.filter((item: any) => item.id !== id);
		setLiveCallsFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = liveCallsFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveTableData = async (filters = '0') => {
		let parasedRows = [];
		let rows: any[] = await getLiveCalls({ campaignId: filters });
		let usrCamps: any[] = await getFilters('Position', { campaignType: 'Predictive', CampaignPosition: 'Running' });
		for (let rw of rows) {
			for (let camp of usrCamps) {
				if (rw['campaignId'] == camp['Id']) {
					rw['campaignName'] = camp['Name'];
				}
			}
			parasedRows.push(rw);
		}
		const columns = [
			{ name: 'UCID', key: 'ucid', align: 'left' },
			{ name: 'Name', key: 'name', align: 'left' },
			{ name: 'Campaign name', key: 'campaignName', align: 'left' },
			{ name: 'Customer number', key: 'PhoneNumber', align: 'left' },
			{ name: 'Customer status', key: 'callStatus', align: 'left' },
			{ name: 'Agent status', key: 'agentStatus', align: 'left' },
			{ name: 'Agent ID', key: 'agentSelected', align: 'left' },
			// { name: 'AHT', key: 'AverageHandlingDuration', align: 'left' }
		];
		setRowData(parasedRows);
		setColumnData(columns);
		// Apply column filters to the data
		applyColumnFilters(parasedRows)
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleFilterExistingData = (filteredData: any) => {
		setLiveCallsFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection, columnFilters: newColumnFilters }: any) => {
		setLiveCallsFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		if (newColumnFilters) {
			setColumnFilters(newColumnFilters);
		}
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (liveCallsFilter.length && !(responseLength === liveCallsFilter.length)) ? liveCallsFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = liveCallsFilter.length ? liveCallsFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.LIVE_CALLS);
	}, [liveCallsFilter]);

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						Live Calls<span className="card-sub-header">(Predictive)</span>
						<span className="card-title-badge">Live</span>
					</div>
					<div className="card-actions">
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} xlsx rowData={filteredData} columns={columnData} name={'Live Calls'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={liveCallsFilter.length + columnFilterLength} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					{(liveCallsFilter.length > 0 || columnFilterLength > 0) && (
						<Box className="customTabPanel">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{liveCallsFilter.length > 0 && filterItems}
												{columnFilterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
				</Box>
				<Box sx={{ height: (liveCallsFilter.length > 0 || columnFilterLength > 0) ? 'calc(100% - 100px)' : 'calc(100% - 28px)' }} ref={chartContainerRef}>
					<OZTable downloadContent={downloadContent} columns={columnData} rows={filteredData} noMaxHeight={true} height={chartDimensions.height} />
				</Box>
			</Card>
			<FilterDrawer
				isOpen={isDrawerOpen}
				toggleDrawer={toggleDrawer}
				applyFilters={handleApplyFilters}
				existingSelectedFilters={liveCallsFilter}
				allowedFilters={['Campaign']}
				filterType={'Position'}
				setResponseLength={setresponseLength}
				additionalApiParams={additionalApiParams}
				existingFilterCategory={filterType}
				applyFilterOnExistingData={handleFilterExistingData}
				filterByColumn={{ columns: ['callStatus', 'agentStatus'], data: rowData }}
				columnFilters={columnFilters}
				setColumnFilters={setColumnFilters}
			/>
		</>
	);
}
export default LiveCalls;
