import { Chip } from '@mui/material';
import React, { useMemo, useState } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export const ColumnDisplayNameMapForFilter: { [key: string]: string } = {
  callStatus: 'Customer Status',
  agentStatus: 'Agent Status',
};

export default function useColumnFilter({
  rowData
}: {
  rowData: any[]
}) {
  const [columnFilters, setColumnFilters] = useState<{ [key: string]: string[] }>({});
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const columnFilterLength = useMemo(() => {
    if (columnFilters && typeof columnFilters === 'object' && Object.keys(columnFilters).length > 0) {
      let count = 0;
      for (let key in columnFilters) {
        if (columnFilters[key] && columnFilters[key]?.length > 0) {
          count += columnFilters[key].length;
        }
      }
      return count;
    }
    return 0;
  }, [columnFilters]);

  // Apply column filters to the data
  const processColumnFilters = (data: any[]) => {
    if (!data || !data.length) return [];

    // If no column filters are applied, return the original data
    if (Object.keys(columnFilters).length === 0) return data;

    return data.filter(item => {
      // For each active column filter
      return Object.keys(columnFilters).every(column => {
        // If no values are selected for this column, don't filter by it
        if (!columnFilters[column] || columnFilters[column].length === 0) {
          return true;
        }

        // If 'ALL' is selected for this column, don't filter by it
        if (columnFilters[column].includes('ALL')) {
          return true;
        }

        // Check if the item's value for this column is in the selected values
        return columnFilters[column].includes(String(item[column]));
      });
    });
  };

  const applyColumnFilters = (data: any[]) => {
    setFilteredData(processColumnFilters(data));
  };

  // Apply column filters when filters change or when rowData changes
  React.useEffect(() => {
    applyColumnFilters(rowData)
  }, [columnFilters, rowData]);

  console.log("columnFilters", columnFilters)

  const columnFilterItems = useMemo(() => {
    return (columnFilterLength > 0) ? <ColumnFilterItems setColumnFilters={setColumnFilters} columnFilters={columnFilters} /> : null
  }, [columnFilters])


  return {
    columnFilters,
    filteredData,
    columnFilterLength,
    applyColumnFilters,
    columnFilterItems,
    setColumnFilters
  }
}

const ColumnFilterItems = ({ setColumnFilters, columnFilters }: {
  setColumnFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>,
  columnFilters: { [key: string]: string[] }
}) => {
  try {
    return (
      <>
        {Object.keys(columnFilters).map((key: any) => {
          if (columnFilters[key]?.length === 0) return null;
          return columnFilters[key].map((item: any, index: number) => (

            <Chip
              key={`${key}-${item}-${index}`}
              label={`${key.toUpperCase()} - ${item}`}
              color="primary"
              variant="outlined"
              onDelete={() => {
                setColumnFilters((prev) => {
                  let newFilters = { ...prev };
                  newFilters[key] = newFilters[key]?.filter((_: any) => _ !== item);
                  return newFilters;
                });
              }}
              deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
          ))
        })}
      </>
    );
  } catch (e) {
    console.log("error", e);
    return null;
  }
}
