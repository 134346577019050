
import { getSettingsListByCode } from "../services/api-service";
import { getisSuperAdminTrue, getUserRoles } from "../services/auth-service";
import jwt from 'jwt-decode';


interface TranscriptField {
	label: string;
	type: string;
	options?: string;
	required?: boolean;
}

interface CarouselItem {
	title: string;
	description: string;
	image: string;
}

interface CarouselOption {
	id: string;
	title: string;
}

const leftFillNum = (num: number, targetLength: number): string => {
	return num.toString().padStart(targetLength, '0');
};
export const secondsToTime = (secondsNum: number): string => {
	if (!secondsNum || secondsNum <= 0) {
		return '00:00:00';
	}
	const hours = Math.floor(secondsNum / 3600);
	const minutes = Math.floor((secondsNum - hours * 3600) / 60);
	const seconds = secondsNum - hours * 3600 - minutes * 60;
	return `${leftFillNum(hours, 2)}:${leftFillNum(minutes, 2)}:${leftFillNum(seconds, 2)}`;
};

export const secondsToMinSec = (secondsNum: number): string => {
	if (!secondsNum || secondsNum <= 0) {
		return '00:00';
	}
	const minutes = Math.floor(secondsNum / 60);
	const seconds = secondsNum - minutes * 60;
	return `${leftFillNum(minutes, 2)}:${leftFillNum(seconds, 2)}`;
};

export const convertSecondsToTimeNew = (secondsNum: number): string => {
	if (!secondsNum || secondsNum <= 0) {
		return '00:00';
	}

	const totalSeconds = Math.round(secondsNum);
	const minutes = Math.floor(totalSeconds / 60);
	const remainingSeconds = totalSeconds % 60;

	return `${leftFillNum(minutes, 2)}:${leftFillNum(remainingSeconds, 2)}`;
};

export const getTotalRowAndActualData = (data: any, key: string, multiple = false) => {
	const totalRow = multiple ? data.filter((item: any) => +item[key] === 0) ?? [] : data.find((item: any) => +item[key] === 0) ?? {};
	const actualData = data.filter((item: any) => item[key] !== '0');
	return { totalRow, actualData };
};
const getRowData = (thresholdList: any, row: any) => {
	const tempRow = { ...row };
	for (const threshold of thresholdList) {
		// console.log("row::::::::::::::::::::", threshold)
		if (!threshold.key || (threshold.key && (row[threshold.key] === threshold.value || row[threshold.key]?.value === threshold.value))) {
			for (const field of threshold.fields) {
				// console.log("field:::::::::::::::", field)
				// console.log("tempRow:::::::::::::::", tempRow)
				let fieldValue;
				if (tempRow[field.key]?.warning) {
					delete tempRow[field.key].warning;
				}
				if (field?.value?.length) {
					switch (field.condition) {
						case 'GREATER_THAN': {
							// let value = tempRow[field.key]?.value || tempRow[field.key] ;
							let value = tempRow[field.key]?.value || tempRow[field.key];
							// console.log("value:::::::::::::", value)
							// console.log("+field.value:::::::::::::", +field.value)
							// console.log("field.key:::::::::::::", field.key)
							if (field.key.toLowerCase().endsWith("time")) {
								fieldValue = secondsToTime(+field.value);
								String(value)
							} else {
								fieldValue = +field.value;
							}
							// console.log("final  value :::::::::::::", fieldValue)
							if (value >= fieldValue) {
								if (typeof tempRow[field.key] === 'object') {
									tempRow[field.key] = { ...tempRow[field.key], warning: 'warning' };
								} else {
									tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
								}
							}
							break;
						}
						case 'LESS_THAN': {
							const value = tempRow[field.key]?.value || tempRow[field.key];
							if (+value <= +field.value) {
								tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
							}
							break;
						}
						default: {
							const value = tempRow[field.key]?.value || tempRow[field.key];
							if (+value >= +field.value) {
								tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
							}
							break;
						}
					}
				}
			}
		}

	}
	return tempRow;
}
export const mapRowDataWithThreshold = (thresholdList: any[], rowData: any[]) => {
	const finalRowData = [];
	if (!rowData.length) {
		// console.log("rowData", rowData)
		finalRowData.push(getRowData(thresholdList, rowData));
	} else {
		for (const row of rowData) {
			// console.log("rowData", rowData)
			finalRowData.push(getRowData(thresholdList, row));
		}
	}
	return finalRowData;
}

export const mapRowDataWithThresholdNew = (thresholdList: any[], rowData: any[]) => {
	const finalRowData = [];
	for (const row of rowData) {
		const tempRow = { ...row };
		for (const threshold of thresholdList) {
			if (threshold.label === "Organization Level") {
				if (threshold.isOrgSkill) {
					for (const orgField of threshold.fields) {
						if (orgField?.value?.length) {
							switch (orgField.condition) {
								case 'GREATER_THAN': {
									let orgNewvalue = tempRow[orgField.key]?.value || tempRow[orgField.key];
									if (+orgNewvalue >= +orgField.value) {
										if (typeof tempRow[orgField.key] === 'object') {
											tempRow[orgField.key] = { ...tempRow[orgField.key], warning: 'warning' };
										} else {
											tempRow[orgField.key] = { value: tempRow[orgField.key], warning: 'warning' };
										}
									}
									break;
								}
								case 'LESS_THAN': {
									const orgNewvalue = tempRow[orgField.key]?.value || tempRow[orgField.key];
									if (+orgNewvalue <= +orgField.value) {
										tempRow[orgField.key] = { value: tempRow[orgField.key], warning: 'warning' };
									}
									break;
								}
								default: {
									const orgNewvalue = tempRow[orgField.key]?.value || tempRow[orgField.key];
									if (+orgNewvalue >= +orgField.value) {
										tempRow[orgField.key] = { value: tempRow[orgField.key], warning: 'warning' };
									}
									break;
								}
							}
						}
					}
					if (threshold.isOrgSkill) {
						break;
					}
				}
			}
			else {
				if (!threshold.key || (threshold.key && (row[threshold.key] === threshold.value || row[threshold.key]?.value === threshold.value))) {
					for (const field of threshold.fields) {
						if (field?.value?.length) {
							switch (field.condition) {
								case 'GREATER_THAN': {
									let value = tempRow[field.key]?.value || tempRow[field.key];
									if (+value >= +field.value) {
										if (typeof tempRow[field.key] === 'object') {
											tempRow[field.key] = { ...tempRow[field.key], warning: 'warning' };
										} else {
											tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
										}
									}
									break;
								}
								case 'LESS_THAN': {
									const value = tempRow[field.key]?.value || tempRow[field.key];
									if (+value <= +field.value) {
										tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
									}
									break;
								}
								default: {
									const value = tempRow[field.key]?.value || tempRow[field.key];
									if (+value >= +field.value) {
										tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
									}
									break;
								}
							}
						}
					}
				}
			}
		}
		finalRowData.push(tempRow);
	}
	return finalRowData;
}

// export function checkRoles(roleName: string) {
// 	let isFound = false;
// 	let roles = getUserRoles();
// 	if (roles != null && roles.length !== 0) {
// 		roles.forEach((role: any) => {
// 			if (role.name === roleName) {
// 				isFound = true;
// 				return
// 			}
// 		});
// 	}
// 	return isFound
// };

export function checkRoles(roleName: string): boolean {
	let roles = getUserRoles();
	if (roles && roles.length > 0) {
		return roles.some((role: any) => role.name === roleName);
	}
	return false;
}
export function checkDataAccessFilters(): boolean {
	const roleCheck = checkRoles("ROLE_DATA_ACCESS");
	const isAdmin = localStorage.getItem('isAdmin') === 'true';
	return roleCheck && !isAdmin;
}

export function checkPaginationForLiveReports(): boolean {//for OZTable condition  whetehr it is sub-admin or admin
	const roleCheck = checkRoles("ROLE_DATA_ACCESS");
	const isAdmin = localStorage.getItem('isAdmin') === 'true';

	if (isAdmin && roleCheck) {
		return true;
	}
	if (!isAdmin) {
		return checkDataAccessFilters();
	}
	return false;
}


export async function checkCustomCDR(): Promise<boolean> {
	const resp = await checkSettings("CDR_COMBINED_DATA");
	const isAdmin = localStorage.getItem('isAdmin') === 'true';

	if (!isAdmin && resp) {
		localStorage.setItem('dataAccessCombined', 'true');
		return true;

	}
	localStorage.setItem('dataAccessCombined', 'false');
	return false;
}

export const getFilterType = (disabledFilters: string[], defaultFilter: number): number => {
	const valueMap: { [key: string]: number } = {
		campaignName: 1,
		skill: 2,
		agent: 8,
		group: 3
	};

	// If no filters are disabled, return 1
	if (disabledFilters.length === 0) {
		return defaultFilter;
	}

	// If all filters are present, return 1
	if (disabledFilters.length === 4) {
		return 1;
	}

	// Determine the CallValue based on missing elements
	const missingValues = Object.keys(valueMap).filter(key => !disabledFilters.includes(key));
	if (missingValues.length > 0) {
		return valueMap[missingValues[0]]; // Return the value of the first missing element
	}

	// Default case (should not be reached)
	return 1;
};


export const determineFilterType = (filtersArray: string[]): any => {
	if (filtersArray[0] === 'Campaign') {
		return 1;
	} else if (filtersArray[0] === 'Skill') {
		return 2;
	} else if (filtersArray[0] === 'Group') {
		return 3;
	}
	return 1;
};

export async function checkSettings(settingName: string): Promise<boolean> {
	try {
		const resp = await getSettingsListByCode({ isAdmin: "true", settingsCode: settingName });
		if (resp?.Data?.length > 0 || resp?.Data !== null) {
			const data = resp?.Data[0];
			if (data?.ParameterValue !== null) {
				if (data?.ParameterValue === "true" || data?.ParameterValue === true) {
					return true;
				} else if (data?.ParameterValue === "false" || data?.ParameterValue === false) {
					return false;
				}
			} else if (data?.ParameterValue === null) {
				if (data?.DefaultValue === "true" || data?.DefaultValue === true) {
					return true;
				} else if (data?.DefaultValue === "false" || data?.DefaultValue === false) {
					return false;
				}
			}
		} else {
			// console.log("Empty data for setting:", settingName);
		}
	} catch (e) {
		// console.log("Error occurred while calling the settings:", e);
	}
	// console.log("Setting not found:", settingName);
	return false;
}

export function checkIsAdminTrue() {
	let isFound = false;
	let jwtToken = getisSuperAdminTrue()
	const tokenJwt: any = jwt(jwtToken);
	if (tokenJwt.isSupperAdmin === true) {
		isFound = true;
	}
	return isFound
}


// export const createLiveFilterIdss = (selectedFilters: any) => {
// 	let filterIds: any = {};
// 	Object.keys(selectedFilters).forEach((key) => {
// 		if (typeof selectedFilters[key] === 'string') {
// 			if (selectedFilters[key] === 'All') {
// 				filterIds[key] = '0';
// 			} else {
// 				filterIds[key] = selectedFilters[key];
// 			}
// 		} else if (key === 'modeType' && typeof selectedFilters[key] === 'object') {
// 			if (selectedFilters[key]?.id === 'All') {
// 				filterIds[key] = '100';
// 				console.log("i am here in if")
// 			} else if (selectedFilters[key]?.id === 'Manual') {
// 				filterIds[key] = '1';
// 			}
// 			else if (selectedFilters[key]?.id === 'Inbound') {
// 				filterIds[key] = '2';
// 			}
// 			else if (selectedFilters[key]?.id === 'Progressive') {
// 				filterIds[key] = '3';
// 			}
// 			else if (selectedFilters[key]?.id === 'Preview') {
// 				filterIds[key] = '4';
// 			}
// 			else if (selectedFilters[key]?.id === 'Blended') {
// 				filterIds[key] = '5';
// 			}
// 			else if (selectedFilters[key]?.id === 'Chat') {
// 				filterIds[key] = '8';
// 			} else {
// 				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
// 			}
// 		}
// 		else if (Array.isArray(selectedFilters[key]) && selectedFilters[key].length > 0) {
// 			if (selectedFilters[key][0].id === 'All') {
// 				filterIds[key] = '0';
// 			} else {
// 				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
// 			}
// 		}
// 		else if (typeof selectedFilters[key] === 'object') {
// 			if (key === 'callType' && selectedFilters[key].name === 'All') {
// 				filterIds[0] = ''; // Append filterIds[0] = "" for "callType"
// 			} else {
// 				filterIds[key] = selectedFilters[key].id;
// 			}
// 		}

// 	});
// 	return filterIds;

// };

export const createLiveFilterIdss = (selectedFilters: any) => { //this is modified code for the Live Filters when passing the value All
	let filterIds: any = {};
	Object.keys(selectedFilters).forEach((key) => {
		if (typeof selectedFilters[key] === 'string') {
			if (selectedFilters[key] === 'All') {
				filterIds[key] = '0';
			} else {
				filterIds[key] = selectedFilters[key];
			}
		} else if (key === 'modeType' && typeof selectedFilters[key] === 'object') {
			if (selectedFilters[key]?.id === 'All') {
				filterIds[key] = '100';
				// console.log("i am here in if")
			} else if (selectedFilters[key]?.id === 'Manual') {
				filterIds[key] = '1';
			}
			else if (selectedFilters[key]?.id === 'Inbound') {
				filterIds[key] = '2';
			}
			else if (selectedFilters[key]?.id === 'Progressive') {
				filterIds[key] = '3';
			}
			else if (selectedFilters[key]?.id === 'Preview') {
				filterIds[key] = '4';
			}
			else if (selectedFilters[key]?.id === 'Blended') {
				filterIds[key] = '5';
			}
			else if (selectedFilters[key]?.id === 'Chat') {
				filterIds[key] = '8';
			} else {
				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
			}
		}
		else if (Array.isArray(selectedFilters[key]) && selectedFilters[key].length > 0) {
			if (key === 'channelType' && selectedFilters[key][0].id === 'All') {
				filterIds[key] = ''; // Append filterIds[0] = "" for "callType"
			} else if (selectedFilters[key][0].id === 'All' && key !== 'channelType') {
				filterIds[key] = '0';
			} else {
				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
			}
		}
		else if (typeof selectedFilters[key] === 'object') {
			if (key === 'callType' && selectedFilters[key].name === 'All') {
				filterIds[0] = ''; // Append filterIds[0] = "" for "callType"
			} else if (selectedFilters[key].name === 'All' && key === 'callEvent') {
				filterIds[key] = '';
			} else if (selectedFilters[key].name === 'All' && key !== 'callType' && key !== 'callEvent' && key !== 'channelType') {
				// filterIds[key] = selectedFilters[key].id;
				filterIds[key] = '0';
			} else {
				filterIds[key] = selectedFilters[key].id;
			}
		}

	});
	return filterIds;

};

export const createLiveFilterIds = (selectedFilters: any) => {
	let filterIds: any = {};
	Object.keys(selectedFilters).forEach((key) => {
		if (typeof selectedFilters[key] === 'string') {
			if (selectedFilters[key] === 'All') {
				filterIds[key] = 0;
			} else {
				filterIds[key] = selectedFilters[key];
			}
		} else if (Array.isArray(selectedFilters[key]) && selectedFilters[key].length > 0) {
			if (selectedFilters[key][0].id === 'All') {
				filterIds[key] = 0;
			} else {
				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
			}
		} else if (typeof selectedFilters[key] === 'object') {
			if (selectedFilters[key].name === 'All') {
				filterIds[key] = 0;
			} else {
				filterIds[key] = selectedFilters[key].id;
			}
		}
	});
	return filterIds;
};

export const createHistoricFilterIds = (selectedFilters: any) => {
	let filterIds: any = {};
	if (selectedFilters) {
		Object.keys(selectedFilters).forEach((key) => {
			const value = selectedFilters[key];
			if (Array.isArray(value)) {
				filterIds[key] = value.map((item: any) => item.id).join(',');
			} else if (typeof value === 'object') {
				filterIds[key] = value.id;
			} else {
				filterIds[key] = value;
			}
		});
	}
	return filterIds;
};

const FILTER_ID_LABELS: { [key: string]: string } = {
	groupNames: 'groupIds',
	phoneNumbers: 'blockNumberIds'
}
export const convertBytes = (bytes: number): string => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
};

export const createFilterIds = (selectedFilters: any) => {
	let filterIds: any = {};
	if (selectedFilters) {
		Object.keys(selectedFilters).forEach((key) => {
			const newKey = FILTER_ID_LABELS[key] || key;

			const value = selectedFilters[key];
			if (Array.isArray(value)) {
				filterIds[newKey] = value.map((item: any) => item.id).join(',');
			} else if (typeof value === 'object' && value !== null) {
				filterIds[newKey] = value.id;
			} else {
				filterIds[newKey] = value;
			}
		});
	}
	return filterIds;
};

export const withoutPercentageChar = (value: any) => {
	const convertedValue = value.slice(0, -2);
	return convertedValue;
};

export const percentageCal = (value: any, total: any) => {
	if (value === 0 || total === 0) {
		return 0;
	}
	return (value / total) * 100;
}

export const getRandomColor = () => {
	return `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.4)`;
};

export const isSubUser = (): boolean => {
	return localStorage.getItem('isAdmin') === 'false';
};

export function generateAuditLog(obj1: any, obj2: any) {
	const differences: any = {};

	function compareArrays(arr1: any, arr2: any, key: any) {
		const hasId = arr1[0]?.hasOwnProperty('id') || arr2[0]?.hasOwnProperty('id');

		if (hasId) {
			// For arrays of objects with IDs
			const ids1 = new Set(arr1.map((item: any) => item.id));
			const ids2 = new Set(arr2.map((item: any) => item.id));

			return {
				added: arr2.filter((item: any) => !ids1.has(item.id)),
				removed: arr1.filter((item: any) => !ids2.has(item.id)),
				modified: arr2.filter((item: any) => {
					const originalItem = arr1.find((i: any) => i.id === item.id);
					return originalItem && JSON.stringify(item) !== JSON.stringify(originalItem);
				})
			};
		} else {
			// For arrays of values (like strings)
			const set1 = new Set(arr1);
			const set2 = new Set(arr2);

			return {
				added: arr2.filter((item: any) => !set1.has(item)),
				removed: arr1.filter((item: any) => !set2.has(item)),
				modified: []
			};
		}
	}

	// Recursively compare all keys
	for (const key in obj2) {
		// Skip if both objects don't have the key
		if (!obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) continue;

		// Handle new keys in obj2
		if (!obj1.hasOwnProperty(key)) {
			differences[key] = { after: obj2[key] };
			continue;
		}

		const val1 = obj1[key];
		const val2 = obj2[key];

		// Handle arrays specially
		if (Array.isArray(val1) && Array.isArray(val2)) {
			const arrayDiff = compareArrays(val1, val2, key);
			if (arrayDiff.added.length > 0 || arrayDiff.removed.length > 0 || arrayDiff.modified.length > 0) {
				differences[key] = arrayDiff;
			}
			continue;
		}

		// Handle objects recursively
		if (typeof val1 === 'object' && typeof val2 === 'object' &&
			val1 !== null && val2 !== null &&
			!Array.isArray(val1) && !Array.isArray(val2)) {
			const nestedDiff = generateAuditLog(val1, val2);
			if (Object.keys(nestedDiff).length > 0) {
				differences[key] = nestedDiff;
			}
			continue;
		}

		// Handle primitive values
		if (val1 !== val2) {
			differences[key] = {
				before: val1,
				after: val2
			};
		}
	}

	// Check for keys that were in obj1 but removed in obj2
	for (const key in obj1) {
		if (!obj2.hasOwnProperty(key)) {
			differences[key] = { before: obj1[key] };
		}
	}

	return differences;
}

export const getDefaultPlugins = () => {
	return [{ "name": "Post Message", "id": 5, }]
}

export const formNameMap: Record<string, Record<string, string>> = {
	campaign: {
		DialCounter: 'Dial Counter',
		DateAdded: 'Date Added',
		DateUpdated: 'Date Updated',
		mapName: 'Map Name',
		campaignName: 'Campaign Name',
		campaignType: 'Campaign Type',
		did: 'DID',
		fallbackDID: 'Fallback DID',
		runtimeStart: 'Start Time',
		runtimeEnd: 'End Time',
		ruleNot: 'No.Of (Call Tries/Concurrent Chat)',
		callPrefix: 'Call Prefix',
		sla: 'Wrapup Time',
		dialInterval: 'Dial Interval',
		customerRingingTime: 'Customer Ringing Time',
		dataLimit: 'Max Concurrent Calls',
		dialCount: 'Daily Outbound Limit per Number',
		dropRatio: 'Max Drop Ratio (%)',
		ruleNac: 'Pacing Ratio(Calls:Agent)',
		ivrFlow: 'IVR Flow',
		previewDataMap: 'Mapping Name',
		blockNumberGroups: 'Block Number Groups',
		appURL: 'App URL',
		amdUrl: 'Voice Message URL',
		amdType: 'AMD Detected',
		screenPopUrlInBound: 'Plugin Name',
		transferSkills: 'Transfer Skills',
		formatPhoneNumber: 'STD Enabled',
		customerFirst: 'Dial Customer First',
		allowedManual: 'Allowed Manual Dial',
		appToApp: 'App to App',
		dndEnable: 'DND Mode',
		voiceBot: 'Voice Bot',
		acwNac: 'ACW for Unanswered Call',
		dncEnabled: 'DNC Regulation',
		didMasking: 'DID Masking',
		holdAgent: 'Dial by Hold Agent',
		offLineMode: 'Offline Mode',
		perDayRetries: 'Max Retries per Day',
		noOfDaysToRetry: 'Max No. of Days to Retry',
		fallbackDynamicDID: 'Fallback Dynamic DID',
		callbackUrl: 'URL to Push',
		popUrlAt: 'Hit ScreenPop URL',
		screenPopUrl: 'ScreenPop URL',
		dispositionType: 'Disposition Type',
		blockNumber: 'Block Number',
		recOn: 'Record',
		disclaimer: 'Play Disclaimer URL',
		holdMusic: 'Hold Music',
		transferMusic: 'Transfer Music',
		amdActive: 'AMD Detection',
		isTransferSkill: 'Transfer Skill',
		a2aCalling: 'Agent to Agent Calling',
		internalCalling: 'Internal Calling',
		screenPopUrlChatInput: 'ScreenPop URL',
		screenPopUrlChat: 'Plugin Name',
		reqType: 'Method',
	}
}

export function formatCamelCaseToSentenceCase(str: string) {
	if (!str) return '';
	return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
}

export const getFormattedNameBasedOnForm = (formName: string, key: string) => {
	if (!key) return '';
	const formNameMapKeys = formNameMap[formName ? formName.toLowerCase() : ''];
	if (formNameMapKeys) {
		return formNameMapKeys[key] || formatCamelCaseToSentenceCase(key);
	}
	return formatCamelCaseToSentenceCase(key);
}


export function getTranscriptModified(item: any, key: any) {
	if (Array.isArray(item[key])) {
		try {
			item[key] = item[key]
				.map((entry: any) => {
					if (!entry) {
						console.error("Invalid entry in transcript");
						return "";
					}

					try {
						// Timestamp handling with validation
						let timestamp;
						try {
							const date = new Date(entry.ts);
							if (isNaN(date.getTime())) {
								throw new Error("Invalid timestamp");
							}
							timestamp = date.toISOString().replace("T", " ").split(".")[0];
						} catch (error) {
							console.error("Error processing timestamp:", error);
							timestamp = "Invalid Date";
						}

						// Sender determination with validation
						const sender = entry.agent
							? `Agent ${entry.agentId || "{AgentName}"}`
							: entry.bot
								? "Bot"
								: "Customer";

						let message = "";

						// CHAT_BOT_HISTORY handling
						if (typeof entry.msg === 'string' && entry.msg.includes('CHAT_BOT_HISTORY')) {
							try {
								const chatHistoryObj = JSON.parse(entry.msg);
								if (!chatHistoryObj.CHAT_BOT_HISTORY) {
									throw new Error("Invalid CHAT_BOT_HISTORY format");
								}
								const chatHistory = JSON.parse(chatHistoryObj.CHAT_BOT_HISTORY);

								return chatHistory
									.map((historyEntry: any) => {
										try {
											const historyTimestamp = new Date(historyEntry.ts).toISOString().replace("T", " ").split(".")[0];
											const historySender = historyEntry.agent === "false" ? "Customer" :
												historyEntry.bot === "true" ? "Bot" :
													historyEntry.agent ? `Agent ${historyEntry.agentId || "{AgentName}"}` :
														"System";

											let historyMessage = "";

											try {
												if (historyEntry.type === "interactive" && historyEntry.msg?.type === "list") {
													const choices = historyEntry.msg.sections?.[0]?.choices || [];
													historyMessage = `${historyEntry.msg.title || "Untitled"}: ` +
														choices.map((choice: any, index: number) =>
															`${index + 1}. ${choice.title || "Untitled"}`
														).join(", ");
												} else if (historyEntry.type === "CCTransfer") {
													historyMessage = "Chat transfer initiated";
												} else if (typeof historyEntry.msg === "string") {
													historyMessage = historyEntry.msg.replace(/<[^>]*>/g, '').trim() || "Empty message";
												} else if (historyEntry.msg) {
													historyMessage = JSON.stringify(historyEntry.msg);
												} else {
													historyMessage = "Empty message";
												}
											} catch (error) {
												console.error("Error processing history message:", error);
												historyMessage = "Error processing message";
											}

											return `[${historyTimestamp}] ${historySender}: ${historyMessage} | `;
										} catch (error) {
											console.error("Error processing history entry:", error);
											return "";
										}
									})
									.filter(Boolean)
									.join(" ");
							} catch (error) {
								console.error("Error processing CHAT_BOT_HISTORY:", error);
								return `[${timestamp}] ${sender}: Error processing chat history | `;
							}
						}

						// Regular message handling
						try {
							if (typeof entry.msg === "string") {
								try {
									const parsedMsg = JSON.parse(entry.msg);

									if (parsedMsg.data?.type === "Form") {
										try {
											const formData = parsedMsg.data;
											if (!formData.fields || !Array.isArray(formData.fields)) {
												throw new Error("Invalid form fields");
											}
											message = `${formData.title || "Untitled Form"}: ` +
												formData.fields.map((field: TranscriptField) => {
													try {
														let fieldInfo = field.label || "Untitled Field";
														if (field.type === "dropdown") {
															fieldInfo += ` (Options: ${field.options || "None"})`;
														}
														fieldInfo += ` [${field.type || "unknown"}]`;
														if (field.required) {
															fieldInfo += " *Required";
														}
														return fieldInfo;
													} catch (error) {
														console.error("Error processing form field:", error);
														return "Error in field";
													}
												}).join(", ");
										} catch (error) {
											console.error("Error processing form:", error);
											message = "Error processing form";
										}
									} else if (parsedMsg.data?.type === "Carousel") {
										try {
											const carouselData = parsedMsg.data.data;
											if (!carouselData?.values || !Array.isArray(carouselData.values)) {
												throw new Error("Invalid carousel data");
											}
											const slides = carouselData.values.map((item: CarouselItem, index: number) =>
												`${index + 1}. Title: ${item.title || "Untitled"}, Description: ${item.description || "No description"}, Image: ${item.image || "No image"}`
											).join(" | ");
											const options = (carouselData.options || []).map((option: CarouselOption, index: number) =>
												`Option ${index + 1}: ${option.title || "Untitled"}`
											).join(", ");
											message = `Carousel Slides: [ ${slides} ] Actions: [ ${options || "None"} ]`;
										} catch (error) {
											console.error("Error processing carousel:", error);
											message = "Error processing carousel";
										}
									} else if (parsedMsg.id?.includes('node_')) {
										try {
											switch (parsedMsg.title) {
												case 'Menu':
													message = 'Navigating to Main Menu';
													break;
												case 'ButtonsNode':
													message = 'Showing Button Options';
													break;
												case 'OfficeHoursNode':
													message = 'Checking Office Hours';
													break;
												case 'CCTransfer1':
													message = `Transfer to: ${parsedMsg.title}`;
													break;
												default:
													message = `Navigating to: ${parsedMsg.title || "Unknown"}`;
											}
										} catch (error) {
											console.error("Error processing node:", error);
											message = "Error processing node";
										}
									} else if (typeof parsedMsg.data === "string" && parsedMsg.data.includes('<p>')) {
										message = parsedMsg.data.replace(/<[^>]*>/g, '').trim() || "Empty HTML content";
									} else if (parsedMsg.type === "list" && parsedMsg.sections?.[0]?.choices) {
										try {
											const choices = parsedMsg.sections[0].choices;
											message = `${parsedMsg.title || "Untitled"}: ` +
												choices.map((choice: any, index: number) =>
													`${index + 1}. ${choice.title || "Untitled"}`
												).join(", ");
										} catch (error) {
											console.error("Error processing list:", error);
											message = "Error processing list";
										}
									} else {
										message = entry.msg.replace(/<[^>]*>/g, '').trim() || "Empty message";
									}
								} catch (parseError) {
									// If JSON parsing fails, treat as plain text
									message = entry.msg.replace(/<[^>]*>/g, '').trim() || "Empty message";
								}
							} else if (entry.type === "interactive" && entry.msg?.type === "list") {
								try {
									const choices = entry.msg.sections?.[0]?.choices || [];
									message = `${entry.msg.title || "Untitled"}: ` +
										choices.map((choice: any, index: number) =>
											`${index + 1}. ${choice.title || "Untitled"}`
										).join(", ");
								} catch (error) {
									console.error("Error processing interactive list:", error);
									message = "Error processing interactive list";
								}
							} else if (entry.msg === null) {
								message = "null";
							} else if (typeof entry.msg === "object") {
								try {
									if (entry.msg.data?.type === "Form") {
										const formData = entry.msg.data;
										if (!formData.fields || !Array.isArray(formData.fields)) {
											throw new Error("Invalid form fields");
										}
										message = `${formData.title || "Untitled Form"}: ` +
											formData.fields.map((field: TranscriptField) => {
												try {
													let fieldInfo = field.label || "Untitled Field";
													if (field.type === "dropdown") {
														fieldInfo += ` (Options: ${field.options || "None"})`;
													}
													fieldInfo += ` [${field.type || "unknown"}]`;
													if (field.required) {
														fieldInfo += " *Required";
													}
													return fieldInfo;
												} catch (error) {
													console.error("Error processing form field:", error);
													return "Error in field";
												}
											}).join(", ");
									} else if (entry.msg.data?.type === "Carousel") {
										try {
											const carouselData = entry.msg.data.data;
											if (!carouselData?.values || !Array.isArray(carouselData.values)) {
												throw new Error("Invalid carousel data");
											}
											const slides = carouselData.values.map((item: CarouselItem, index: number) =>
												`${index + 1}. Title: ${item.title || "Untitled"}, Description: ${item.description || "No description"}, Image: ${item.image || "No image"}`
											).join(" | ");
											const options = (carouselData.options || []).map((option: CarouselOption, index: number) =>
												`Option ${index + 1}: ${option.title || "Untitled"}`
											).join(", ");
											message = `Carousel Slides: [ ${slides} ] Actions: [ ${options || "None"} ]`;
										} catch (error) {
											console.error("Error processing carousel:", error);
											message = "Error processing carousel";
										}
									} else if (entry.msg.id?.includes('node_')) {
										try {
											switch (entry.msg.title) {
												case 'Menu':
													message = 'Navigating to Main Menu';
													break;
												case 'ButtonsNode':
													message = 'Showing Button Options';
													break;
												case 'OfficeHoursNode':
													message = 'Checking Office Hours';
													break;
												case 'CCTransfer1':
													message = `Transfer to: ${entry.msg.title}`;
													break;
												default:
													message = `Navigating to: ${entry.msg.title || "Unknown"}`;
											}
										} catch (error) {
											console.error("Error processing node:", error);
											message = "Error processing node";
										}
									} else if (typeof entry.msg.data === "string" && entry.msg.data.includes('<p>')) {
										message = entry.msg.data.replace(/<[^>]*>/g, '').trim() || "Empty HTML content";
									} else {
										message = JSON.stringify(entry.msg) || "Empty object";
									}
								} catch (error) {
									console.error("Error processing object message:", error);
									message = "Error processing message";
								}
							} else if (entry.type === "CCTransfer") {
								message = "Chat transfer initiated";
							} else if (entry.msg) {
								message = JSON.stringify(entry.msg) || "Empty message";
							} else {
								message = "Empty message";
							}
						} catch (messageError) {
							console.error("Error processing message:", messageError);
							message = "Error processing message";
						}

						return `[${timestamp}] ${sender}: ${message} | `;
					} catch (entryError) {
						console.error("Error processing transcript entry:", entryError);
						return "";
					}
				})
				.filter(Boolean) // Remove empty strings
				.join(" ");
		} catch (transcriptError) {
			console.error("Error processing transcript:", transcriptError);
			item.transcript = "Error processing transcript";
		}
	} else {
		return item;
	}
}

export function replaceKeyInObject(obj: any, oldKey: any, newKey: any) {
	if (!obj || typeof obj !== 'object' || obj === null) {
		return "Invalid input: Object must be a non-null object.";
	}

	if (!(oldKey in obj)) {
		return "Old key not found in the object."
	}

	const newObj: { [key: string]: any } = {};
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (key === oldKey) {
				newObj[newKey] = obj[oldKey]; // Assign the VALUE of the old key to the new key
			} else {
				newObj[key] = obj[key];
			}
		}
	}
	return newObj;
}