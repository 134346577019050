import { Assignment } from '@mui/icons-material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Button, Card, Grid, IconButton, Menu, MenuItem, Pagination, Paper, Rating, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { makeStyles, styled } from '@mui/styles';
import React, { useEffect, useMemo } from 'react';
import AlertIcon from '../../static/icons/Alert.svg';
import HappyEmojiIcon from '../../static/icons/happy-emoji.svg';
import SadEmojiIcon from '../../static/icons/sad-emoji.svg';
import { convertSecondsToTimeNew, getRandomColor, secondsToMinSec, secondsToTime } from '../../utils/util';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import DefaultUserIcon from '../DefaultUserIcon/DefaultUserIcon';
import '../OZTable/OZTable.css';
import TableSortLabel from '@mui/material/TableSortLabel';
import SelectPopover from '../SelectPopover/SelectPopover';
import OZRatingComponent from '../admin/common/OZRatingComponent/OZRatingComponent';
import OZRatingCommentNew from '../admin/common/OZRatingCommentNew/OZRatingCommentNew';
import AudioPlayerSeparateComp from '../admin/common/AudioPlayerSeparateComp/AudioPlayerSeparateComp';
import AgentStaffedProgress from '../ProgressBar/ProgressBar';
import VideoPlayer from '../admin/common/VideoPlayer/VideoPlayer';
import AuditComparison from '../admin/common/AuditComparison/AuditComparison.jsx';

export type Column = {
	name: string;
	key: string;
	subTitle: any;
	align: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
	type?: 'MIN_SEC' | 'TIME' | 'RATING' | 'CHAT' | 'AUDIO' | 'VIDEO' | 'AGENT_NAME_COLOR' | 'NumberMasking' | 'RATINGCOMMENTS' | 'CallQueueCount' | 'callerDisconnects' | 'agentStaffedSplit' | 'hamburger' | 'number' | "TotalAgentCount" | "AUDIT";
	sortable?: boolean;
	sticky?: boolean;
	minWidth?: any;
	isStringValue?: boolean;//for case in-sensitive sorting(through query) of string values
};

type Props = {
	name?: string; //this i am calling from the callDetails and CallDetailsLiveReport and VoiceMail report 
	handleOnClickRating?: any;
	handleOnClickRatingComments?: any;
	columns: Column[];
	rows: any[];
	maxHeight?: number | string;
	height?: number | string;
	pagination?: boolean;
	component?: React.ElementType<any> | undefined;
	ref?: React.MutableRefObject<HTMLElement | null>;
	onReassign?(row: any): void;
	onDeleteClick?(row: any): void;
	onReportClick?(row: any): void;
	onChatClick?: any;
	handleModalData?(row: any): void;
	onPageChangeEvent?: any;
	pageController?: any;
	totalCount?: any;
	onRowsChangeEvent?: any;
	noMaxHeight?: boolean;
	onTableColumnSort?: any;
	totalRows?: any;
	handleOnclickUcid?: any;
	additionalColumns?: any[];
	onColumnsChange?(obj: any): void;
	downloadContent?: any;
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: '#000'
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#000'
	}
}));

const useStyles = makeStyles({
	pagination: {
		display: 'flex',
		alignItems: 'center',
		padding: '1rem',
		justifyContent: 'space-between'
	},
	paginationLeftContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '1rem'
	},
	rowsPerPageMenuWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '0.5rem'
	},
	rowsPerPageMenuButton: {
		borderColor: '#99A0A8 !important',
		color: 'black !important'
	},
	rowsPerPageMenuButtonIcon: {
		color: '#99A0A8 !important'
	},
	rowsPerPageMenu: {
		borderRadius: '8px'
	},
	rowsPerPageMenuItem: {
		border: 'solid !important',
		borderColor: 'rgb(0,0,0,0.1) !important',
		borderWidth: '1px 0 0 0 !important',
		padding: '0.25rem 1rem !important',
		'&:nth-last-child(1)': {
			border: 'solid !important',
			borderColor: 'rgb(0,0,0,0.1) !important',
			borderWidth: '1px 0 1px 0 !important'
		}
	},
	tableCard: { borderRadius: '11px 11px !important', border: '1px solid #E6E5E6 !important', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04) !important', width: 'inherit !important' },
	defaultUserIcon: {
		minWidth: '1.8rem',
		minHeight: '1.8rem',
		backgroundColor: '#F2C2C2',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: '400',
		fontSize: '10px',
		textAlign: 'center'
	},
	userIcon: {
		backgroundColor: 'unset'
	},
	stickyCell: {
		padding: 0,
		position: 'sticky',
		right: 0,
		background: 'white'
	},
	fixed: {
		position: 'sticky',
		right: 0
	},
	defaultUserSmallIcon: {
		height: '6px',
		width: '6px',
		borderRadius: '3px',
		marginTop: '6px',
		marginRight: '6px'
	}
});

type Order = 'asc' | 'desc';

const OZTable = ({
	name, //this i am calling from the callDetails and CallDetailsLiveReport and VoiceMail report 
	handleOnClickRating,
	handleOnClickRatingComments,
	rows,
	columns,
	maxHeight,
	height,
	pagination,
	component: Component = Box,
	ref,
	onReassign,
	onDeleteClick,
	onReportClick,
	onChatClick,
	handleModalData,
	onPageChangeEvent,
	pageController,
	totalCount,
	onRowsChangeEvent,
	noMaxHeight = false,
	onTableColumnSort,
	totalRows,
	handleOnclickUcid,
	additionalColumns,
	onColumnsChange,
	downloadContent
}: Props) => {
	if (!rows) rows = [];
	const classes = useStyles();

	const gridItemStyle = useMemo(
		() => ({
			fontWeight: 400,
			fontSize: "12px",
			color: "#000000"
		}),
		[]
	);

	useEffect(() => {
		setPage(pageController?.page || 0);
	}, [rows]);

	const [page, setPage] = React.useState(pageController?.page || 0);
	const [rowsPerPage, setRowsPerPage] = React.useState(pageController?.rowsPerPage || 10);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const displayRowsPerPageMenu = Boolean(anchorEl);
	const displayedRows = !onPageChangeEvent && pagination ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState('');
	const [isColumnsPopoverOpen, setIsColumnsPopoverOpen] = React.useState<boolean>(false);
	const [columnsEleRefPoint, setColumnsElRefPoint] = React.useState<any>(null);
	const [selectedColumns, setSelectedColumns] = React.useState<any>(additionalColumns);
	const [anchorAudio, setAnchorAudio] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorAudio);

	const onHamburgerClick = (event: any) => {
		setColumnsElRefPoint({ top: event.clientY, left: event.clientX });
		setIsColumnsPopoverOpen(true);
	};

	const handleOnColumnsPopoverClose = () => {
		setColumnsElRefPoint(null);
		setIsColumnsPopoverOpen(false);
	};

	const handleOnSelectColumnChange = (selected: any) => {
		setSelectedColumns(selected);
		onColumnsChange && onColumnsChange(selected);
		handleOnColumnsPopoverClose();
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		if (onPageChangeEvent) {
			onPageChangeEvent(newPage);
		}
		if (onPageChangeEvent) {
			setPage(newPage);
		} else {
			setPage(newPage - 1);
		}
	};

	const handleChangeRowsPerPage = (event: any) => {
		if (onRowsChangeEvent) {
			onRowsChangeEvent(event);
		}
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setAnchorEl(null);
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleRowsPerPageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseRowsPerPageMenu = () => {
		setAnchorEl(null);
	};

	const tableContainerStyle: any = { maxHeight: maxHeight || 360 };
	if (noMaxHeight) {
		tableContainerStyle.maxHeight = 'none';
	}
	if (height) {
		tableContainerStyle.height = height;
	}
	const handleTableColumnSort = (columnKey: any, isStringValue: boolean = false) => {
		let sortingOrder;
		if (order === 'asc') {
			sortingOrder = 0;
			setOrder('desc');
		} else {
			sortingOrder = 1;
			setOrder('asc');
		}
		onTableColumnSort(columnKey, sortingOrder, isStringValue);
	};

	const getTableHeaderContent = (column: any) => {
		return (
			<>
				{column.name}
				{column.subTitle >= 0 && (
					<>
						<br />
						<span>({column.type === 'MIN_SEC' ? secondsToMinSec(column.subTitle) : column.type === 'TIME' ? secondsToTime(column.subTitle) : column.subTitle})</span>
					</>
				)}
				{column.key === 'hamburger' && (
					<Stack>
						<IconButton onClick={onHamburgerClick}>
							<img src={`${process.env.PUBLIC_URL}/icons/table-hamburger-icon.svg`} width="20" alt="Hamburger" style={{ marginTop: '5px' }}></img>
						</IconButton>
					</Stack>
				)}
			</>
		);
	};

	// const handleClick = (event: React.MouseEvent<HTMLElement>) => {
	// 	setAnchorAudio(event.currentTarget);
	// };

	// const handleClose = () => {
	// 	setAnchorAudio(null);
	// };
	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<>
				<Grid item xs={12}>
					<div className="card-table-container">
						<Component className={Component === Card && classes.tableCard} ref={ref}>
							<TableContainer component={Paper} className="custom-table custom-table-scroll" sx={{ ...tableContainerStyle }}>
								<Table ref={downloadContent} stickyHeader sx={{ minWidth: 650 }} size="small">
									<TableHead>
										<TableRow>
											{columns?.map((column, idx) => (
												<TableCell
													key={idx}
													align={column.align || 'center'}
													sortDirection={column.sortable && orderBy === column.key ? order : false}
													className={column.sticky ? classes.fixed : ''}
												>
													{column.sortable ? (
														<TableSortLabel
															active={orderBy === column.key}
															direction={orderBy === column.key ? order : 'asc'}
															onClick={() => handleTableColumnSort(column.key, column.isStringValue)}
															hideSortIcon={false}
														>
															{getTableHeaderContent(column)}
															{<img src={`${process.env.PUBLIC_URL}/img/sorting-icon.svg`} alt="sortIcon" className="sort-icon" />}
														</TableSortLabel>
													) : (
														getTableHeaderContent(column)
													)}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{displayedRows.map((row: any, rowIdx: number) => (
											<TableRow
												key={rowIdx}
												sx={{
													background: row?.hoursTotal === 'SUB TOTAL' ? ' #D6D6D7 !important' : row?.Total ? '#536580 !important' : ''
												}}
												className={row?.background || ''}
											>
												{columns?.map((column, columnIdx) => (
													<TableCell
														key={columnIdx}
														component={columnIdx === 0 ? 'th' : 'td'}
														align={column.align || 'center'}
														className={`${row[column.key]?.warning || ''} ${column.type === 'AUDIO' || column.type === 'CHAT' ? classes.stickyCell : ''}`}
														sx={{ minWidth: column?.minWidth ? column?.minWidth : '', color: row?.Total ? '#F2F2F2 !important' : row?.SubTotal ? ' #00000 !important' : '', overflowWrap: (column?.key === 'uui' || column?.key === 'UUI') ? 'anywhere' : '' }}
													>
														{(row[column.key]?.value === 'error_icon' && (
															<Stack direction={'row'} spacing={2}>
																{/* <SvgIcon>{SadEmoji}</SvgIcon> */}
																<img src={`${SadEmojiIcon}`} alt="icon" />
																<img src={`${AlertIcon}`} alt="icon" />
															</Stack>
														)) ||
															(column.type === 'number' && row[column.key] == 0 && '0') ||
															(column.type === 'NumberMasking' && <span>*****</span>) ||
															(column.type === 'CHAT' && (
																<Stack direction="row" gap={1}>
																	<Button style={{ color: '#3D8BF8', minWidth: '100px' }} onClick={() => onChatClick(row)}>
																		Chat History
																	</Button>
																</Stack>
															)) ||
															(row[column.key]?.value === 'success_icon' && (
																<Stack direction={'row'} spacing={2}>
																	{/* <SvgIcon>{SadEmoji}</SvgIcon> */}
																	<img src={`${HappyEmojiIcon}`} alt="icon" />
																</Stack>
															)) ||
															(row[column.key]?.icon &&
																(row[column.key]?.icon === 'user_icon' ? (
																	<Stack direction={'row'} gap={2} alignItems="center">
																		{<DefaultUserIcon word={row[column.key]?.value} />}
																		<Tooltip title={row[column.key]?.value}>
																			<Typography sx={{ ...gridItemStyle, textOverflow: 'ellipsis', maxWidth: '250px', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer',}}>{row[column.key]?.value}</Typography>
																		</Tooltip>
																	</Stack>
																) : (
																	<Stack direction={'row'} gap={2} alignItems="center">
																		<Box className={`${classes.userIcon} ${classes.defaultUserIcon}`}>
																			<img src={row[column.key]?.icon} alt="profile" />
																		</Box>
																		<Tooltip title={row[column.key]?.value}>
																			<Typography sx={{...gridItemStyle, textOverflow: 'ellipsis', maxWidth: '250px', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer',}}>{row[column.key]?.value}</Typography>
																		</Tooltip>
																	</Stack>
																))) ||
															(row[column.key]?.color &&
																(row[column.key]?.color === 'green' ? (
																	<Stack sx={{ color: '#4F9F52' }}>{row[column.key]?.value}</Stack>
																) : (
																	<Stack sx={{ color: '#E75B5C' }}>{row[column.key]?.value}</Stack>
																))) ||
															(row[column.key]?.Color && <Stack sx={{ color: row[column.key]?.Color }}>{row[column.key]?.value}</Stack>) ||
															(column.type === 'AUDIO' && (
																<>
																	<AudioPlayerSeparateComp row={row} rowIdx={rowIdx} column={column} />
																	{/* <Stack minWidth={'fit-content'} sx={{}}> //this is commented as getting issue of re-rendering
																	{Array.isArray(row[column.key]) ? (
																		row[column.key]?.length > 1 && (
																			<Box display={'flex'}>
																				<div style={{ marginRight: '12px' }}>
																					<AudioPlayer url={row[column.key][0]} idx={rowIdx} />
																				</div>
																				<Button size="small" variant="contained" onClick={handleClick}>
																					+{row[column.key].length - 1}
																				</Button>
																				<Menu anchorEl={anchorAudio} open={open} onClose={handleClose}>
																					{row[column.key]?.map(
																						(url: any, index: any) =>
																							index !== 0 && (
																								<MenuItem key={index}>
																									<AudioPlayer url={url} idx={rowIdx} />
																								</MenuItem>
																							)
																					)}
																				</Menu>
																			</Box>
																		)
																	) : (
																		<AudioPlayer url={row[column.key]} idx={rowIdx} />
																	)}
																</Stack> */}
																</>
															)) ||
															(column.type === 'VIDEO' && (
																<>
																	<VideoPlayer row={row} />
																</>
															)) ||
															(column.key === 'REASSIGN_DELETE' && onReassign && onDeleteClick && row.CallBackIsDeleted !== '1' && (
																<Stack direction="row" gap={1}>
																	<Button style={{ color: '#536580' }} onClick={() => onReassign(row)}>
																		Reassign Agent
																	</Button>
																	<span style={{ marginTop: '8px', cursor: 'pointer' }}>
																		<img src={`${process.env.PUBLIC_URL}/icons/delete-icon.svg`} alt="delete" onClick={() => onDeleteClick(row)} />
																		{/* <DeleteIcon onClick={() => onDeleteClick(row)} /> */}
																	</span>
																</Stack>
															)) ||
															(column.key === 'isReport' && onReportClick && (
																<Stack direction="row" gap={1}>
																	<Assignment onClick={() => onReportClick(row)}></Assignment>
																</Stack>
															)) ||
															(column.type === 'RATING' && (
																<>
																	<Stack sx={{ minWidth: 70 }} direction={'row'} spacing={2}>
																		<OZRatingComponent name={name} handleOn={handleOnClickRating} defaultValue={row[column.key] !== '' ? parseFloat(row[column.key]) : 0} row={row} />
																		{/* <Rating name="size-small" defaultValue={+row[column.key] || 0} size="small" disabled /> */}
																	</Stack>
																</>
															)) ||

															(column.type === 'RATINGCOMMENTS' && (  //this is for making the Input box for Comments 
																<>
																	<Stack sx={{ minWidth: 90 }} direction={'row'} spacing={2}>
																		<OZRatingCommentNew handleValueInComment={handleOnClickRatingComments} name={name} defaultValue={row[column.key] || ''} row={row} />
																	</Stack>
																</>
															)) ||
															(column.type === 'CallQueueCount' && (
																<>
																	<Stack sx={{ minWidth: 70 }} direction={'column'} spacing={0.5}>
																		<div>{row[column.key]}</div>
																		<div style={{ fontWeight: 500 }}>{secondsToMinSec(row?.LongestQueueTime || 0)}</div>
																	</Stack>
																</>
															)) ||
															(column.type === 'TotalAgentCount' && (
																<>
																	<Stack sx={{ minWidth: 70 }} direction={'column'} spacing={0.5}>
																		<div>{row[column.key] ? row[column.key] || 0 : 0}</div>
																	</Stack>
																</>
															)) ||
															(column.type === 'callerDisconnects' && (
																<>
																	<Stack sx={{ minWidth: 70 }} direction={'column'} spacing={0.5}>
																		<div>{`${row[column.key] || ''}`}</div>
																		<div style={{ fontWeight: 500 }}>{row?.TotalAbandonedCalls || ''}</div>
																	</Stack>
																</>
															)) ||
															(column.type === 'agentStaffedSplit' && (
																<>
																	<Stack sx={{ minWidth: 70 }} direction={'column'} spacing={0.5}>
																		<AgentStaffedProgress data={row?.agentStaffedSplit} />
																	</Stack>
																</>
															)) ||

															(column.type === 'AGENT_NAME_COLOR' && (
																<Stack direction={'row'} spacing={2}>
																	<Box className={classes.defaultUserSmallIcon} style={{ backgroundColor: getRandomColor() }}></Box>
																	{row[column.key]}
																</Stack>
															)) ||
															(row[column.key]?.isClickable && handleOnclickUcid && (
																<Stack className={row[column.key]?.eventColor}>
																	<Button className={row[column.key]?.eventColor} onClick={() => handleOnclickUcid(row)}>
																		{row[column.key]?.value}
																	</Button>
																</Stack>
															)) ||
															(column.type === 'AUDIT' && (
																<AuditComparison row={row} column={column} />
															)) ||
															(column.type === 'MIN_SEC' && secondsToMinSec(row[column.key]?.value || row[column.key])) ||
															(column.type === 'TIME' && secondsToTime(row[column.key]?.value || row[column.key])) ||
															(row[column.key]?.value && (
																<Tooltip title={row[column.key]?.value}>
																	<Typography sx={{...gridItemStyle, textOverflow: "ellipsis", maxWidth: "250px", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }} >{row[column.key]?.value}</Typography>
																</Tooltip>
															)) ||
															(row[column.key] && (
																<Tooltip title={row[column.key]}>
																	<Typography sx={{...gridItemStyle, textOverflow: "ellipsis", maxWidth: "250px", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }} >{row[column.key]}</Typography>
																</Tooltip>
															)) ||
															''}
														{row[column.key]?.badge && (
															<BootstrapTooltip
																title={
																	<React.Fragment>
																		{row[column.key]?.extraValues?.map((eachExtraValue: any, idx: number) => (
																			<div key={idx}>
																				<span>
																					{eachExtraValue}
																					{idx < row[column.key]?.extraValues.length - 1 && ','}
																				</span>
																				<br />
																			</div>
																		))}
																	</React.Fragment>
																}
																arrow
															>
																<span className="badge">{row[column.key]?.badge || ''}</span>
															</BootstrapTooltip>
														)}
													</TableCell>
												))}
											</TableRow>
										))}
										{pagination && emptyRows > 0 && (
											<TableRow
											// style={{
											// 	height: 42 * emptyRows
											// }}
											>
												<TableCell colSpan={columns.length} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Component>
						{pagination && (
							<Box className={classes.pagination}>
								<Box className={classes.paginationLeftContainer}>
									<Box>
										<>
											Total Rows: {''}
											<b> {totalRows}</b>
										</>
									</Box>
									<Box className={classes.rowsPerPageMenuWrapper}>
										<Button
											id="rowsPerPageMenuButton"
											aria-controls={displayRowsPerPageMenu ? 'rowsPerPageMenu' : undefined}
											aria-haspopup="true"
											aria-expanded={displayRowsPerPageMenu ? 'true' : undefined}
											variant="outlined"
											// sx={{ backgroundColor: 'gray' }}
											className={classes.rowsPerPageMenuButton}
											disableElevation
											onClick={handleRowsPerPageMenuOpen}
											endIcon={<KeyboardArrowDownOutlinedIcon className={classes.rowsPerPageMenuButtonIcon} />}
										>
											{rowsPerPage}
										</Button>
										<Menu
											elevation={0}
											open={displayRowsPerPageMenu}
											id="rowsPerPageMenu"
											MenuListProps={{
												'aria-labelledby': 'rowsPerPageMenuButton',
												className: classes.rowsPerPageMenu
											}}
											anchorEl={anchorEl}
											onClose={handleCloseRowsPerPageMenu}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center'
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center'
											}}
										>
											<MenuItem className={classes.rowsPerPageMenuItem} onClick={handleChangeRowsPerPage} value={10} disableRipple>
												10
											</MenuItem>
											<MenuItem className={classes.rowsPerPageMenuItem} onClick={handleChangeRowsPerPage} value={25} disableRipple>
												25
											</MenuItem>
											<MenuItem className={classes.rowsPerPageMenuItem} onClick={handleChangeRowsPerPage} value={50} disableRipple>
												50
											</MenuItem>
											<MenuItem className={classes.rowsPerPageMenuItem} onClick={handleChangeRowsPerPage} value={100} disableRipple>
												100
											</MenuItem>
										</Menu>
										<Typography>Rows per page</Typography>
									</Box>
								</Box>

								<Pagination
									// count={rows.length / rowsPerPage + (rows.length % rowsPerPage > 0 ? 1 : 0)}
									// count={Math.ceil(rows.length / rowsPerPage)}
									count={totalCount || Math.ceil(rows.length / rowsPerPage)}
									variant="outlined"
									shape="rounded"
									color="primary"
									page={onPageChangeEvent ? page : page + 1}
									onChange={handleChangePage}
									siblingCount={0}
									boundaryCount={2}
								/>
							</Box>
						)}
					</div>
				</Grid>
				<SelectPopover
					isOpen={isColumnsPopoverOpen}
					elementsArray={additionalColumns || []}
					anchorRef={columnsEleRefPoint}
					handlePopoverClose={handleOnColumnsPopoverClose}
					selectedElements={selectedColumns}
					handleOnSelectionChange={handleOnSelectColumnChange}
					addMetricsLabel
				/>
			</>
		</Box>
	);
};

export default OZTable;
