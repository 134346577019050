import * as XLSX from 'xlsx';

function convertToCSV(objArray: any[]) {
	const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;

	let str = '';
	for (const row of array) {
		let line = '';
		for (const column in row) {
			if (line !== '') line += ',';
			let value = row[column];
			if (value === "" || value === null || value === undefined) {
				value = ' ';
			}
			if (typeof value === 'string' && value.includes(',')) {
				value = `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
			}
			line += value;
		}
		str += line + '\r\n';
	}
	return str;
}

const getFormattedItems = (items: any[], headers: any) => {
	const newItems = JSON.parse(JSON.stringify(items)).map((item: any) => {
		const newItem: any = {};
		Object.keys(headers).forEach((key) => {
			if (item[key]) {
				newItem[headers[key]] = typeof item[key] === 'object' ? item[key].value : item[key] || '';
			} else {
				newItem[headers[key]] = '';
			}
		});
		return newItem;
	});
	return newItems;
};

export function exportToCSV(headers: any, items: any[], fileName: string = '') {
	const formattedItems = getFormattedItems(items, headers);
	if (headers) {
		formattedItems.unshift(headers);
	}
	const csvString = convertToCSV(formattedItems);
	const exportedFilename = fileName.length && fileName.endsWith('.csv') ? fileName : 'export.csv';
	const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement('a');
	if (link.download !== undefined) {
		const url = URL.createObjectURL(blob);
		link.setAttribute('href', url);
		link.setAttribute('download', exportedFilename);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}

export function exportToXLSX(headers: any, items: any[], fileName: string = '') {
	items = getFormattedItems(items, headers);
	const headerArr: string[] = Object.values(headers);
	const ws = XLSX.utils.json_to_sheet(items, { header: headerArr });
	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
	const exportedFilename = fileName.length && fileName.endsWith('.xlsx') ? fileName : 'export.xlsx';
	XLSX.writeFile(wb, exportedFilename);
}
