// WhatsAppTemplateVariables.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  Stack,
} from '@mui/material';
import { Upload } from 'lucide-react';

export interface WhatsAppVariable {
  type: 'header_image' | 'header_video' | 'header_text' | 'header_document' | 'body' | 'carousel';
  name: string;
  placeholder?: string;
  value: string | File | null;
  format?: string;
  cardIndex?: number;
}

interface WhatsAppTemplateVariablesProps {
  variables: Record<string, string>;
  onVariableChange: (name: string, value: string | File) => void;
  onFileChange: (file: File) => void;
  extraData: Record<string, any>;
  getFieldError: (field: string) => string | undefined;
  isSubmitting?: boolean;
  category?: string | null | undefined;
}

const WhatsAppTemplateVariables: React.FC<WhatsAppTemplateVariablesProps> = ({
  variables,
  onVariableChange,
  onFileChange,
  extraData,
  getFieldError,
  isSubmitting = false,
  category
}) => {
  const [processedVariables, setProcessedVariables] = useState<WhatsAppVariable[]>([]);

  useEffect(() => {
    try {
      const processed: WhatsAppVariable[] = [];

      Object.entries(variables).forEach(([key, placeholder]) => {
        // Handle carousel variables
        if (key.startsWith('carousel_cards_')) {
          const parts = key.split('_');
          const cardIndex = parseInt(parts[2]);
          const variableType = parts[3]; // header, body, buttons
          const format = parts[4]; // image, video, text, etc.

          if (variableType === 'header') {
            processed.push({
              type: `header_${format}` as any,
              name: key,
              value: extraData[key] || null,
              placeholder: '',
              cardIndex,
              format
            });
          } else if (variableType === 'body' && category !== "dynamic") {
            processed.push({
              type: 'body',
              name: key,
              value: extraData[key] || '',
              placeholder: placeholder,
              cardIndex
            });
          } else if (variableType === 'buttons' && category !== "dynamic") {
            processed.push({
              type: 'body', // Handle button variables as body type
              name: key,
              value: extraData[key] || '',
              placeholder: placeholder,
              cardIndex
            });
          }
        }
        // Handle regular header variables
        else if (key.startsWith('header_')) {
          const format = key.split('_')[1];
          processed.push({
            type: key.startsWith('header_image') ? 'header_image' :
              key.startsWith('header_video') ? 'header_video' :
                key.startsWith('header_text') ? 'header_text' :
                  'header_document',
            name: key,
            value: extraData[key] || null,
            placeholder: '',
            format
          });
        }
        // Handle body variables
        else if (key.startsWith('body_')) {
          processed.push({
            type: 'body',
            name: key,
            value: extraData[key] || '',
            placeholder: placeholder
          });
        }
      });

      // Sort variables by card index and type
      processed.sort((a, b) => {
        if (a.cardIndex !== undefined && b.cardIndex !== undefined) {
          if (a.cardIndex !== b.cardIndex) {
            return a.cardIndex - b.cardIndex;
          }
        }
        return a.name.localeCompare(b.name);
      });

      setProcessedVariables(processed);
    } catch (error) {
      console.error('Error processing variables:', error);
    }
  }, [variables, extraData, category]);

  const getAcceptTypes = (type: string) => {
    switch (type) {
      case 'header_image':
        return '.jpg,.jpeg,.png';
      case 'header_video':
        return '.mp4';
      case 'header_document':
        return '.pdf,.doc,.docx';
      default:
        return '';
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, variable: WhatsAppVariable) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Set the correct data attribute
    event.target.dataset.fileType = 'whatsapp-template';

    // Define specific file type validations for WhatsApp headers
    const headerValidations = {
      header_image: {
        accept: ['.jpg', '.jpeg', '.png'],
        mimeTypes: ['image/jpeg', 'image/jpg', 'image/png'],
        maxSize: 5 * 1024 * 1024, // 5MB
        errorMessage: 'Please upload a valid image (JPG, JPEG, PNG) under 5MB'
      },
      header_video: {
        accept: ['.mp4'],
        mimeTypes: ['video/mp4'],
        maxSize: 16 * 1024 * 1024, // 16MB
        errorMessage: 'Please upload a valid MP4 video under 16MB'
      },
      header_document: {
        accept: ['.pdf', '.doc', '.docx'],
        mimeTypes: [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ],
        maxSize: 10 * 1024 * 1024, // 10MB
        errorMessage: 'Please upload a valid document (PDF, DOC, DOCX) under 10MB'
      }
    };

    const validation = headerValidations[variable.type as keyof typeof headerValidations];
    if (!validation) {
      console.error('Unknown header type:', variable.type);
      return;
    }

    // Check file extension
    const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
    if (!validation.accept.includes(fileExtension)) {
      alert(`Invalid file type. Allowed formats: ${validation.accept.join(', ')}`);
      return;
    }

    // Check MIME type
    if (!validation.mimeTypes.includes(file.type)) {
      alert(`Invalid file format. Please upload a valid ${variable.type.split('_')[1]} file.`);
      return;
    }

    // Check file size
    if (file.size > validation.maxSize) {
      alert(`File size exceeds ${validation.maxSize / (1024 * 1024)}MB limit`);
      return;
    }

    // Pass both the file and its metadata
    onFileChange(file);
    onVariableChange(variable.name, file);
  };

  const renderVariableInput = (variable: WhatsAppVariable) => {
    const error = getFieldError(variable.name);
    const cardLabel = variable.cardIndex !== undefined ? `Card ${variable.cardIndex + 1} - ` : '';

    if (variable.type === 'header_text') {
      return (
        <TextField
          key={variable.name}
          fullWidth
          label={`${cardLabel}Header Text`}
          value={extraData[variable.name] || ''}
          onChange={(e) => onVariableChange(variable.name, e.target.value)}
          error={!!error}
          helperText={error || 'Enter header text'}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
          placeholder={variable.placeholder || ''}
        />
      );
    }

    if (variable.type.startsWith('header_')) {
      const fileTypeLabel = variable.type.split('_')[1];
      const currentFile = extraData[variable.name];

      return (
        <Box key={variable.name} sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {`${cardLabel}${fileTypeLabel.charAt(0).toUpperCase() + fileTypeLabel.slice(1)}`}
          </Typography>
          <Button
            component="label"
            variant="outlined"
            startIcon={<Upload size={18} />}
            disabled={isSubmitting}
            sx={{
              textTransform: 'none',
              color: '#4B87FF',
              borderColor: error ? '#DC2626' : '#4B87FF',
              '&:hover': {
                borderColor: error ? '#DC2626' : '#3D78E6'
              }
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={16} sx={{ mr: 1 }} />
            ) : (
              `Upload ${fileTypeLabel}`
            )}
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, variable)}
              accept={getAcceptTypes(variable.type)}
              data-file-type="whatsapp-template"
            />
          </Button>
          {currentFile && (
            <Typography variant="caption" sx={{ display: 'block', mt: 0.5, color: '#4B87FF' }}>
              {currentFile instanceof File ? currentFile.name :
                typeof currentFile === 'string' ? currentFile : ''}
            </Typography>
          )}
          {error && (
            <FormHelperText error>{error}</FormHelperText>
          )}
          <FormHelperText>
            {variable.type === 'header_image' && 'Supported: JPG, JPEG, PNG (max 5MB)'}
            {variable.type === 'header_video' && 'Supported: MP4 (max 16MB)'}
            {variable.type === 'header_document' && 'Supported: PDF, DOC, DOCX (max 10MB)'}
          </FormHelperText>
        </Box>
      );
    } else {
      // Text input for body variables
      return (
        <TextField
          key={variable.name}
          fullWidth
          label={`${cardLabel}${variable.name.split('_').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')} ${variable.placeholder ? `(${variable.placeholder})` : ''}`}
          value={extraData[variable.name] || ''}
          onChange={(e) => onVariableChange(variable.name, e.target.value)}
          error={!!error}
          helperText={error}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
          placeholder={variable.placeholder || ''}
        />
      );
    }
  };

  const renderCarouselCard = (cardIndex: number) => {
    const cardVariables = processedVariables.filter(v => v.cardIndex === cardIndex);
    if (cardVariables.length === 0) return null;

    return (
      <Box key={cardIndex} sx={{ mb: 3, p: 2, border: '1px solid #E5E7EB', borderRadius: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          Card {cardIndex + 1}
        </Typography>
        <Stack spacing={2}>
          {cardVariables.map(variable => renderVariableInput(variable))}
        </Stack>
      </Box>
    );
  };

  // Group variables by card index
  const cardIndices = [...new Set(processedVariables
    .filter(v => v.cardIndex !== undefined)
    .map(v => v.cardIndex))] as number[];

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="subtitle1" gutterBottom>
        Template Variables
      </Typography>

      {/* Render non-carousel variables first */}
      <Stack spacing={2}>
        {processedVariables
          .filter(v => v.cardIndex === undefined)
          .map(variable => renderVariableInput(variable))}
      </Stack>

      {/* Render carousel cards */}
      {cardIndices.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Carousel Cards
          </Typography>
          {cardIndices.map(index => renderCarouselCard(index))}
        </Box>
      )}
    </Box>
  );
};

export default WhatsAppTemplateVariables;