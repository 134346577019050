import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../ReportsDrawer/ReportsDrawer.css';
import { getAccessibleLiveReports, getAccessibleHistoricReports, hasAnyLiveReportAccess, hasAnyHistoricReportAccess } from '../../services/page-service';

interface ReportItem {
	name: string;
	route: string;
	group?: string;
	displayPriority?: number;
}

interface ReportsDrawerProps {
	isOpen: HTMLElement | null;
	handleReportsDrawer: (isOpen: boolean) => void;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

// Component to render a single report link
const ReportLink: React.FC<{ item: ReportItem; onClick: () => void; isActive: boolean }> = ({ item, onClick, isActive }) => {
	if (!item?.route || !item?.name) return null;

	return (
		<Link
			to={item.route}
			onClick={onClick}
			className={`historic-link-styling ${isActive ? 'active' : ''}`}
		>
			{item.name}
		</Link>
	);
};

// Component to render a group of reports in an accordion
const ReportGroup: React.FC<{
	groupName: string;
	items: ReportItem[];
	expanded: string | false;
	onAccordionChange: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
	onItemClick: () => void;
	getActiveClass: (route: string) => string;
}> = ({ groupName, items, expanded, onAccordionChange, onItemClick, getActiveClass }) => {
	if (!groupName || !Array.isArray(items) || items.length === 0) return null;

	return (
		<Accordion expanded={expanded === groupName} onChange={onAccordionChange(groupName)} className="accordian-heading">
			<AccordionSummary
				aria-controls={`${groupName}-content`}
				id={`${groupName}-header`}
				className="accordian-summary"
			>
				<span className="accordian-item">{groupName}</span>
			</AccordionSummary>
			{items
				.filter(item => item?.name && item?.route) // Filter out invalid items
				.sort((a, b) => {
					const priorityDiff = ((a?.displayPriority || 0) - (b?.displayPriority || 0));
					return priorityDiff !== 0 ? priorityDiff : (a?.name || '').localeCompare(b?.name || '');
				})
				.map((item, index) => (
					<AccordionDetails key={index} className="expanded-item">
						<Link
							onClick={onItemClick}
							className={`accordian-item acc-sub-item ${getActiveClass(item.route)}`}
							to={item.route}
						>
							{item.name}
						</Link>
					</AccordionDetails>
				))}
		</Accordion>
	);
};

export default function ReportsDrawer({ isOpen, handleReportsDrawer }: ReportsDrawerProps) {
	const location = useLocation();
	const [expanded, setExpanded] = React.useState<string | false>('Dashboard');
	const [currentTab, setCurrentTab] = React.useState('');
	const [currentLocation, setCurrentLocation] = React.useState<string>('');
	const [isLiveTabSelected, setIsLiveTabSelected] = React.useState(true);
	const [isHistoricTabSelected, setIsHistoricTabSelected] = React.useState(false);

	const [accessibleLiveReports, setAccessibleLiveReports] = React.useState<ReportItem[]>([]);
	const [accessibleHistoricReports, setAccessibleHistoricReports] = React.useState<ReportItem[]>([]);
	const [hasLiveAccess, setHasLiveAccess] = React.useState(false);
	const [hasHistoricAccess, setHasHistoricAccess] = React.useState(false);

	const liveTabBorder = isLiveTabSelected ? 'tab-selected' : '';
	const historicTabSelected = isHistoricTabSelected ? 'tab-selected' : '';

	const handleLiveTab = () => {
		setIsLiveTabSelected(true);
		setIsHistoricTabSelected(false);
	};

	const handleHistoricTab = () => {
		setIsLiveTabSelected(false);
		setIsHistoricTabSelected(true);
	};

	const handleDrawer = () => {
		try {
			localStorage.removeItem("ps");
		} catch (error) {
			console.error('Error removing ps from localStorage:', error);
		}
		handleReportsDrawer(!isOpen);
	};

	const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	const handleAccordianChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	const locationActiveClass = (route: string): string => {
		return (currentLocation && route && currentLocation.includes(route)) ? 'active' : '';
	};

	// Group reports by their group property
	const groupReports = (reports: ReportItem[], excludeGroups: string[] = []): Record<string, ReportItem[]> => {
		if (!Array.isArray(reports)) return {};

		return reports
			.filter(item => item?.group && !excludeGroups.includes(item.group))
			.reduce((groups: Record<string, ReportItem[]>, item) => {
				const group = item.group!;
				if (!groups[group]) {
					groups[group] = [];
				}
				groups[group].push(item);
				return groups;
			}, {});
	};

	// Sort groups according to predefined order
	const sortGroups = (groups: [string, ReportItem[]][]): [string, ReportItem[]][] => {
		if (!Array.isArray(groups)) return [];

		const groupOrder: Record<string, number> = {
			'Call Analytics': 2,
			'Agent Reports': 3,
			'Digital': 4,
			'Dynamic': 5,
			'Custom Reports': 6
		};
		return groups.sort((a, b) => (groupOrder[a?.[0]] || 99) - (groupOrder[b?.[0]] || 99));
	};

	React.useEffect(() => {
		try {
			const liveReports = getAccessibleLiveReports() || [];
			const historicReports = getAccessibleHistoricReports() || [];
			const hasLive = hasAnyLiveReportAccess() || false;
			const hasHistoric = hasAnyHistoricReportAccess() || false;

			setAccessibleLiveReports(Array.isArray(liveReports) ? liveReports : []);
			setAccessibleHistoricReports(Array.isArray(historicReports) ? historicReports : []);
			setHasLiveAccess(!!hasLive);
			setHasHistoricAccess(!!hasHistoric);

			if (hasLive) {
				setCurrentTab('1');
				setIsLiveTabSelected(true);
				setIsHistoricTabSelected(false);
			} else if (hasHistoric) {
				setCurrentTab('2');
				setIsLiveTabSelected(false);
				setIsHistoricTabSelected(true);
			}
		} catch (error) {
			console.error('Error initializing reports:', error);
			setAccessibleLiveReports([]);
			setAccessibleHistoricReports([]);
			setHasLiveAccess(false);
			setHasHistoricAccess(false);
		}
	}, []);

	React.useEffect(() => {
		setCurrentLocation(location?.pathname || '');
	}, [location]);

	if (!hasLiveAccess && !hasHistoricAccess) {
		return null;
	}

	const renderHistoricReports = () => {
		if (!Array.isArray(accessibleHistoricReports)) return null;

		return (
			<div className="accordian-container">
				{/* Home */}
				{accessibleHistoricReports
					.filter(item => item?.name === 'Home')
					.map((item, index) => (
						<ReportLink key={index} item={item} onClick={handleDrawer} isActive={locationActiveClass(item.route) !== ''} />
					))}

				{/* Dashboard Group */}
				{Object.entries(groupReports(accessibleHistoricReports, ['Global']))
					.filter(([group]) => group === 'Dashboard')
					.map(([groupName, items], index) => (
						<ReportGroup
							key={index}
							groupName={groupName}
							items={items}
							expanded={expanded}
							onAccordionChange={handleAccordianChange}
							onItemClick={handleDrawer}
							getActiveClass={locationActiveClass}
						/>
					))}

				{/* Call Details */}
				{accessibleHistoricReports
					.filter(item => item?.name === 'Call Details')
					.map((item, index) => (
						<ReportLink key={index} item={item} onClick={handleDrawer} isActive={locationActiveClass(item.route) !== ''} />
					))}

				{/* Other Groups */}
				{sortGroups(Object.entries(groupReports(accessibleHistoricReports, ['Global', 'Dashboard'])))
					.map(([groupName, items], index) => (
						<ReportGroup
							key={index}
							groupName={groupName}
							items={items}
							expanded={expanded}
							onAccordionChange={handleAccordianChange}
							onItemClick={handleDrawer}
							getActiveClass={locationActiveClass}
						/>
					))}

				{/* Downloaded Reports */}
				{accessibleHistoricReports
					.filter(item => item?.name === 'Downloaded Reports')
					.map((item, index) => (
						<ReportLink key={index} item={item} onClick={handleDrawer} isActive={locationActiveClass(item.route) !== ''} />
					))}
			</div>
		);
	};

	return (
		<Popper
			open={!!isOpen}
			role={undefined}
			placement="right"
			transition
			disablePortal
			sx={{
				left: '83px !important',
				top: '76px !important',
			}}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom-start' ? 'left right' : 'left bottom',
					}}
				>
					<Paper
						sx={{
							marginLeft: '7px',
							borderRadius: '8px',
							backgroundColor: 'white',
							boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
							border: '1px solid #E6E5E6',
							minHeight: '78vh !important',
							maxHeight: '83vh !important',
							overflow: 'scroll'
						}}
						onMouseLeave={handleDrawer}
					>
						<ClickAwayListener onClickAway={handleDrawer}>
							<MenuList
								id="reportsMenu"
								aria-labelledby="reportsModule"
								className="reports-drawer-container"
							>
								<div className="live-historic-tabs-container">
									{currentTab && (
										<Box sx={{ width: '100%', typography: 'body1', mt: 2 }}>
											<TabContext value={currentTab}>
												<Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="box-contianer">
													<TabList onChange={handleTabChange} aria-label="lab API tabs example" className="tabs-holder">
														{hasLiveAccess && (
															<Tab
																label="Live"
																value="1"
																className={`live-button ${liveTabBorder}`}
																onClick={handleLiveTab}
															/>
														)}
														{hasHistoricAccess && (
															<Tab
																label="Historic"
																value="2"
																className={`live-button no-border ${historicTabSelected}`}
																onClick={handleHistoricTab}
															/>
														)}
													</TabList>
												</Box>
												{hasLiveAccess && (
													<TabPanel value="1" className="live-tab-content-container">
														<div className="live-reports-bar">
															{Array.isArray(accessibleLiveReports) && accessibleLiveReports.map((item, index) => (
																<ReportLink
																	key={index}
																	item={item}
																	onClick={handleDrawer}
																	isActive={locationActiveClass(item.route) !== ''}
																/>
															))}
														</div>
													</TabPanel>
												)}
												{hasHistoricAccess && (
													<TabPanel value="2" className="historic-tab-content-container">
														{renderHistoricReports()}
													</TabPanel>
												)}
											</TabContext>
										</Box>
									)}
								</div>
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
}
