import React, { useState, useEffect } from 'react'
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import { Button, Stack, Tooltip } from '@mui/material';
import './Integration.css'
import CustomSelect from '../Users/CustomSelect';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { getAgentIdMappings, getHubspotMappings, getZohoMappings, saveHubspotAgentMapping, saveZohoAgentMapping } from '../../../services/api-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

export default function AgentIntegration(mainprops) {

  const [agentIntegrationList, setAgentIntegrationList] = useState([]);
  // console.log(agentIntegrationList)
  const [agentZohoData, setAgentZohoData] = useState();
  const [agentHubspotData, setAgentHubspotData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (mainprops.integration === "zoho") {
      getZohoMappingsDetails();
    }
    else {
      getHubspotMappingsDetails();
    }
  }, []);

  useEffect(() => {
    getAgentList();
  }, [agentZohoData, agentHubspotData])

  async function getZohoMappingsDetails() {
    setAlreadyAssignedZohoUsers([]);
    setLoading(true);
    try {
      getZohoMappings()
        .then((resp) => {
          if (resp.Status === "Success") {
            let zohoData = resp.Data;
            let username = (localStorage.getItem("selectedUser") !== null && localStorage.getItem("selectedUser") !== undefined && localStorage.getItem("selectedUser") !== "null" && localStorage.getItem("selectedUser") !== "undefined") ? localStorage.getItem("selectedUser") : localStorage.getItem("userName");
            let filteredZohoAgentsMap = {};
            Object.entries(zohoData.zohoAgentsMap).forEach(([key, value]) => {
              const [prefix, suffix] = key.split(':');
              if (prefix === username && (value !== "-1" && value !== -1)) {
                filteredZohoAgentsMap[suffix] = value;
              }
            });
            setAgentZohoData({
              zohoAgentsMap: filteredZohoAgentsMap,
              zohoUsers: zohoData.zohoUsers
            });
            const zohoUsersValues = Object.values(zohoData.zohoUsers);
            const updatedOptions = ["DeSelect", ...zohoUsersValues];
            setOptions(updatedOptions);
          }
          else {
            showErrorNotification("Zoho Mappings Cannot Be Fetched .", resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Error in fetching Zoho Mappings");
          // setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in getting Zoho Mappings");
      setLoading(false);
    }
  };

  // async function convertAgentMapDataFormat(hubspotAgentsMap) {
  //   let newHubspotAgentsMap = {};

  //   Object.keys(hubspotAgentsMap).forEach((key) => {
  //     // Parse the inner JSON string
  //     let innerObject = JSON.parse(hubspotAgentsMap[key]);

  //     // Get the first (and only) key-value pair in the inner object
  //     let innerKey = Object.keys(innerObject)[0];
  //     let innerValue = innerObject[innerKey];

  //     // Create the new key and assign the value
  //     let newKey = key.split(':')[0] + ':' + innerKey;
  //     newHubspotAgentsMap[newKey] = innerValue;
  //   });

  //   return newHubspotAgentsMap;
  // }

  async function getHubspotMappingsDetails() {
    // console.log("here");
    setAlreadyAssignedHubspotUsers([]);
    setLoading(true);
    try {
      await getHubspotMappings()
        .then(async (resp) => {
          if (resp.Status === "Success") {
            let hubspotData = resp.Data;
            // let dum = hubspotData.hubspotAgentsMap;
            // let convertedAgentMapObject = {}
            // if(hubspotData.hubspotAgentsMap) {
            //   convertedAgentMapObject = await convertAgentMapDataFormat(dum)
            // }
            // hubspotData = { ...hubspotData, hubspotAgentsMap: convertedAgentMapObject };
            let username = (localStorage.getItem("selectedUser") !== null && localStorage.getItem("selectedUser") !== undefined && localStorage.getItem("selectedUser") !== "null" && localStorage.getItem("selectedUser") !== "undefined") ? localStorage.getItem("selectedUser") : localStorage.getItem("userName");
            let filteredHubspotAgentsMap = {};
            if (hubspotData.hubspotAgentsMap && Object.entries(hubspotData.hubspotAgentsMap).length > 0) {
              Object.entries(hubspotData.hubspotAgentsMap).forEach(([key, value]) => {
                const [prefix, suffix] = key.split(':');
                if (prefix === username && (value !== "-1" && value !== -1)) {
                  filteredHubspotAgentsMap[suffix] = value;
                }
              });
            }
            setAgentHubspotData({
              hubspotAgentsMap: filteredHubspotAgentsMap,
              hubspotUsers: hubspotData.hubspotUsers
            });
            const hubspotUsersValues = Object.values(hubspotData.hubspotUsers);
            const updatedOptions = ["DeSelect", ...hubspotUsersValues];
            setOptions(updatedOptions);
          }
          else {
            showErrorNotification("Hubspot Mappings Cannot Be Fetched .", resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Error in fetching Hubspot Mappings");
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in getting Hubspot Mappings");
      setLoading(false);
    }
  };


  const [options, setOptions] = useState([])
  const [alreadyAssignedZohoUsers, setAlreadyAssignedZohoUsers] = useState([])
  const [alreadyAssignedHubspotUsers, setAlreadyAssignedHubspotUsers] = useState([])

  const UserRenderer = (props) => {

    const [isEditMode, setEditMode] = useState(false);
    const [zohoUser, setZohoUser] = useState(props.data.zohoUser);
    const [hubspotUser, setHubspotUser] = useState(props.data.hubspotUser);

    const toggleEditMode = () => {
      setEditMode(!isEditMode);
      if (mainprops.integration === "zoho") {
        setZohoUser(props.data.zohoUser);
      }
      else {
        setHubspotUser(props.data.hubspotUser)
      }
    };

    async function saveAgentMapping() {
      // console.log("props save", props)
      try {
        setLoading(true);
        if (mainprops.integration === "zoho") {

          const zohoUserVal = zohoUser;
          let result = null;
          for (const [key, value] of Object.entries(agentZohoData.zohoUsers)) {
            if (value === zohoUserVal) {
              result = key;
              break;
            }
          }
          if (zohoUserVal === "DeSelect") {
            result = "-1";
          }
          let data = {
            caAgentId: props.data.agentId,
            zohoUser: result
          }

          saveZohoAgentMapping(data)
            .then((resp) => {
              if (resp.Status === "Success") {
                if (zohoUserVal !== "DeSelect") {
                  showSuccessNotification(`Agent ${props.data.agentName} is Successfully Mapped with zohoUser ${zohoUser}`);
                }
                if (zohoUserVal === "DeSelect") {
                  showSuccessNotification(`Agent ${props.data.agentName} is Successfully UnMapped`);
                }
                setEditMode(false);
                getZohoMappingsDetails();
              }
              else {
                showErrorNotification("Error in save of Zoho Agent Mapping.", resp.Message);
                setLoading(false);
              }
            })
            .catch((e) => {
              showErrorNotification("Error in saving Zoho Agent Mapping");
              // setLoading(false);
            });
        }
        else {

          const hubspotUserVal = hubspotUser;
          let result = null;
          for (const [key, value] of Object.entries(agentHubspotData.hubspotUsers)) {
            if (value === hubspotUserVal) {
              result = key;
              break;
            }
          }
          if (hubspotUserVal === "DeSelect") {
            result = "-1";
          }
          let data = {
            caAgentId: props.data.agentName,
            hubspotUser: result
          }

          saveHubspotAgentMapping(data)
            .then((resp) => {
              if (resp.Status === "Success") {
                if (hubspotUserVal !== "DeSelect") {
                  showSuccessNotification(`Agent ${props.data.agentName} is Successfully Mapped with hubspotUser ${hubspotUser}`);
                }
                if (hubspotUserVal === "DeSelect") {
                  showSuccessNotification(`Agent ${props.data.agentName} is Successfully UnMapped`);
                }
                setEditMode(false);
                getHubspotMappingsDetails();
              }
              else {
                showErrorNotification("Error in save of Hubspot Agent Mapping.", resp.Message);
                setLoading(false);
              }
            })
            .catch((e) => {
              showErrorNotification("Error in saving Hubspot Agent Mapping");
              // setLoading(false);
            });
        }
      } catch (e) {
        showErrorNotification(`Error in ${mainprops.integration} Agent Mapping Saving`);
        setLoading(false);
      }
    }

    return (
      <>
        {!isEditMode &&
          <div className='editButtonPosition'>
            <div>{mainprops.integration === "zoho" ? zohoUser : hubspotUser}</div>
            <Button variant="text" onClick={toggleEditMode} className='editButton'> Edit </Button>
          </div>
        }
        {isEditMode &&
          <>
            <Stack direction="row">
              <CustomSelect
                options={options}
                file="integration"
                placeholder={mainprops.integration === "zoho" ? zohoUser : hubspotUser}
                onChange={mainprops.integration === "zoho" ? setZohoUser : setHubspotUser}
                disabledOptions={mainprops.integration === "zoho" ? alreadyAssignedZohoUsers : alreadyAssignedHubspotUsers}
                disableOptions={true}
              />
              <Stack direction="row" spacing="20px">
                <Button variant="text" onClick={toggleEditMode} className='discardButton'> Discard </Button>
                {mainprops.integration === "zoho" &&
                  <Button variant="contained" className={(zohoUser !== "" && props.data.zohoUser !== zohoUser) ? (zohoUser === "DeSelect") ? (props.data.zohoUser !== "") ? 'saveButton' : 'notAllowSaveButton' : 'saveButton' : 'notAllowSaveButton'} onClick={(zohoUser !== "" && props.data.zohoUser !== zohoUser) ? (zohoUser === "DeSelect") ? (props.data.zohoUser !== "") ? saveAgentMapping : null : saveAgentMapping : null} size="small"> Save </Button>
                }
                {mainprops.integration === "hubspot" &&
                  <Button variant="contained" className={(hubspotUser !== "" && props.data.hubspotUser !== hubspotUser) ? (hubspotUser === "DeSelect") ? (props.data.hubspotUser !== "") ? 'saveButton' : 'notAllowSaveButton' : 'saveButton' : 'notAllowSaveButton'} onClick={(hubspotUser !== "" && props.data.hubspotUser !== hubspotUser) ? (hubspotUser === "DeSelect") ? (props.data.hubspotUser !== "") ? saveAgentMapping : null : saveAgentMapping : null} size="small"> Save </Button>
                }
              </Stack>
            </Stack>
          </>
        }
      </>
    );
  };

  const ColumnRenderer = (props) => {
    return (
      <Tooltip title={(props.value) ? props.value : ''}>
        <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: "pointer" }}>{props.value}</span>
      </Tooltip>
    );
  };

  const columnDefs = ([
    { field: 'agentId', headerName: 'Agent Id', maxWidth: 160, cellRenderer: ColumnRenderer },
    { field: 'agentName', headerName: 'Agent Name', maxWidth: 160, cellRenderer: ColumnRenderer },
    { field: mainprops.integration === "zoho" ? 'zohoUser' : 'hubspotUser', headerName: mainprops.integration === "zoho" ? 'Zoho User' : 'Hubspot User', cellRenderer: UserRenderer },
  ]);

  async function getAgentList() {
    try {
      if (mainprops.integration === "zoho") {
        getAgentIdMappings()
          .then((resp) => {
            if (resp.Status === "Success") {
              let arrayOfObjects = resp.Data.filter(item => item.active === true)
              const updatedArrayOfObjects = arrayOfObjects.map(obj => {
                const lowercaseAgentId = obj.agentId.toLowerCase().replace(/[a-z]/g, char => char.toLowerCase());
                const zohoUser = agentZohoData.zohoAgentsMap.hasOwnProperty(lowercaseAgentId) ?
                  (agentZohoData.zohoUsers.hasOwnProperty(agentZohoData.zohoAgentsMap[lowercaseAgentId]) ?
                    agentZohoData.zohoUsers[agentZohoData.zohoAgentsMap[lowercaseAgentId]] : "") : "";
                if (zohoUser !== "") {
                  setAlreadyAssignedZohoUsers(prevUsers => [...prevUsers, zohoUser])
                }
                return {
                  ...obj,
                  zohoUser: zohoUser
                };
              });
              setAgentIntegrationList(updatedArrayOfObjects);
              setLoading(false);
            }
            else {
              showErrorNotification(resp.Message);
              setLoading(false);
            }
          })
          .catch((e) => {
            setAgentIntegrationList([]);
            // setLoading(false);
          });
      }
      else {
        getAgentIdMappings()
          .then((resp) => {
            if (resp.Status === "Success") {
              let arrayOfObjects = resp.Data.filter(item => item.active === true)
              const updatedArrayOfObjects = arrayOfObjects.map(obj => {
                const lowercaseAgentName = obj.agentName.toLowerCase().replace(/[a-z]/g, char => char.toLowerCase());
                const hubspotUser = agentHubspotData.hubspotAgentsMap.hasOwnProperty(lowercaseAgentName) ?
                  (agentHubspotData.hubspotUsers.hasOwnProperty(agentHubspotData.hubspotAgentsMap[lowercaseAgentName]) ?
                    agentHubspotData.hubspotUsers[agentHubspotData.hubspotAgentsMap[lowercaseAgentName]] : "") : "";
                if (hubspotUser !== "") {
                  setAlreadyAssignedHubspotUsers(prevUsers => [...prevUsers, hubspotUser])
                }
                return {
                  ...obj,
                  hubspotUser: hubspotUser
                };
              });
              setAgentIntegrationList(updatedArrayOfObjects);
              setLoading(false);
            }
            else {
              showErrorNotification(resp.Message);
              setLoading(false);
            }
          })
          .catch((e) => {
            setAgentIntegrationList([]);
            // setLoading(false);
          });

      }
    } catch (e) {
      showErrorNotification("Error in agent list");
      setLoading(false);
    }
  };

  return (
    <div className="ozAdminSubIntegrationContainer">
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        <OZDataGrid height="26.3rem" data={agentIntegrationList} columns={columnDefs} />
      }
    </div>
  );
};